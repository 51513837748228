import {Component, OnInit, OnDestroy, Inject, Output, EventEmitter} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {ConditionOperator} from 'src/modules/models/enum';
import {Question} from 'src/modules/models/item/question';
import {FacilitySummary} from 'src/modules/models/asset/facility-summary';
import {QuestionUtility} from 'src/modules/models/question/question-utility';

import {AlertMessageComponent} from 'src/modules/shared/alert-message/alert-message.component';
import {Store, ICart, UpdateFacility, UpdateBookingPackage} from 'src/modules/store/index';
import {Observable} from 'rxjs';
import {BespokePackage} from 'src/modules/models/asset/bespoke-package';

import {Item} from '../../../models/item/item';
import {verifyHostBindings} from '@angular/compiler';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'opt-questionnaire',
  templateUrl: './questionnaire.component.html'
})

export class QuestionnaireComponent implements OnInit, OnDestroy {
  cart$: Observable<ICart>;
  cart_subscriber: any;
  isFormValid: boolean = true;
  item: Item;
  facilitySummary: FacilitySummary;
  package: BespokePackage;
  isFullBookingView: boolean = false;
  booking: any;
  answeredCurrentCount: number = 0;
  isReadOnly: boolean;
  isUpsellHasQuestion: boolean = false;
  upsellTotalQuestion: number = 0;
  @Output() onSubmit = new EventEmitter<any>(true);
  isAnswerChanged: boolean = false;
  visibleQuestionsCount: number = 0;
  //itemVisibleQuestionsCount: number = 0;


  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<any>,
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) {
    //Package Question =>  will have  this.package
    //Facility Question=> data.fc(facility summary) , item is facility Item
    //                     this schenario item can haved upsell linked in that case upsell also need to be shown in popup
    //Upsell question=> when add upsell this will call and it shows upsell questions only, this scenaio item is upsell item and isUpsellItem is true
    //Booking questions

    this.facilitySummary = data.fc;
    this.cart$ = this.store.select('cart');
    this.item = data.item;

    if (data.packageFilter && data.packageFilter.PackageDetail) {
      this.package = data.packageFilter.PackageDetail;
      //// Loop through package questions and set isHidden for child questions
      //if (this.package && this.package.question) {
      //  this.package.question.forEach(question => {
      //    this.setHiddenFalseForChildQuestions(this.package.question, question.id, true);
      //  });
      //}
    }
    // Second condition
    else if (data.isFullBookingView == true && data.booking) {
      this.isFullBookingView = data.isFullBookingView;
      this.booking = data.booking;

    }
    // Set isReadOnly
    this.isReadOnly = (data.isReadOnly == true);

    //if (this.item != undefined) {
    //  this.item.question = (data.item) ? data.item.question as Question[] : [];
    //  if (!data.isUpsellItem && this.facilitySummary.upsellSummaries && this.facilitySummary.upsellSummaries.length > 0) {
    //    this.facilitySummary.upsellSummaries.forEach((upslSum) => {
    //      if (upslSum.question) {
    //        this.isUpsellHasQuestion = true;
    //        //this.upsellTotalQuestion += upslSum.question.length;
    //        upslSum.question.forEach(question => {
    //            this.setHiddenFalseForChildQuestions(this.item.question as Question[], question.id, true);
    //            if (!question.isHidden) {
    //                this.upsellTotalQuestion += 1;
    //            }
    //        });
    //      }
    //    });
    //  } else {
    //    if (!data.isUpsellItem && data.item) {
    //      this.item.question.forEach(question => {
    //          this.setHiddenFalseForChildQuestions(this.item.question as Question[], question.id, true);
    //          if (!question.isHidden) {
    //              this.upsellTotalQuestion += 1;
    //          }
    //      });
    //    }

    //  }
    //}

    this.countAnswer();

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.cart_subscriber) {
      this.cart_subscriber.unsubscribe();
    }
  }

  updateQuestion(ques) {
    this.isAnswerChanged = true;
    this.countAnswer();
  }

  //countAnswer() {
  //    var quesCount = 0;
  //  var count = 0;
  //  this.isFormValid = true;
  //  if (!this.isFullBookingView) {
  //    if (this.item) {
  //      if (!this.data.isUpsellItem) {
  //          for (var q of this.item.question) {
  //                  var isQuestionAnswered = this.isAnswerd(this.item.question, q);
  //                  if (!q.isHidden) {
  //                      quesCount++
  //                      if (isQuestionAnswered) {
  //                          count++;
  //                      } else if ((q.type == 'textQuestion' || q.type == 'booleanQuestion' || q.type == 'singleChoiceQuestion' || q.type == 'fileUploadQuestion')
  //                          && (q.answeredText !== undefined && q.answeredText != null && q.answeredText != '') && q.mandatory !== undefined) {
  //                          count++;
  //                      } else if (q.type == 'multipleChoiceQuestion') {
  //                          var answeredCount = 0;
  //                          if (q.bookingQuestionChoices && q.isBookingQuestion) {
  //                              q.bookingQuestionChoices.forEach((answ, indx) => {
  //                                  //if (q.quantityRequired) {
  //                                  //    if (answ.Quantity>0) {
  //                                  //        answeredCount++;
  //                                  //    }
  //                                  //} else if (answ.isSelectedAnswer) {
  //                                  //    answeredCount++;
  //                                  //}
  //                                  if (answ.isSelectedAnswer) {
  //                                      answeredCount++;
  //                                  }
  //                              });
  //                              if (answeredCount > 0) {
  //                                  count++;
  //                              }
  //                          } else if (q.answerChoices && !q.isBookingQuestion) {
  //                              q.answerChoices.forEach((answ, indx) => {
  //                                  if (q.quantityRequired) {
  //                                      if (answ.Quantity) {
  //                                          answeredCount++;
  //                                      }
  //                                  } else if (answ.isSelectedAnswer) {
  //                                      answeredCount++;
  //                                  }
  //                              });
  //                              if (answeredCount > 0) {
  //                                  count++;
  //                              }
  //                          }
  //                          if (q.mandatory && answeredCount == 0) {
  //                              this.isFormValid = false;
  //                          }
  //                      }
  //                      else if (q.mandatory) {
  //                          this.isFormValid = false;
  //                      }
  //                  }
  //        }
  //      }

  //      if (this.facilitySummary.upsellSummaries) {
  //        this.facilitySummary.upsellSummaries.forEach((upsl, i) => {
  //            if (upsl.question) {
  //                for (var q of upsl.question) {

  //                    var isQuestionAnswered = this.isAnswerd(upsl.question, q); // Call to isAnswerd method
  //                    if (!q.isHidden) {
  //                        quesCount++
  //                        if (isQuestionAnswered) {
  //                            count++;
  //                        } else if ((q.type == 'textQuestion' || q.type == 'booleanQuestion' || q.type == 'singleChoiceQuestion')
  //                            && (q.answeredText !== undefined && q.answeredText != null && q.answeredText != '')
  //                            && q.mandatory !== undefined) {
  //                            count++;
  //                        } else if (q.type == 'multipleChoiceQuestion') {
  //                            var answeredCount = 0;
  //                            if (q.bookingQuestionChoices && q.isBookingQuestion) {
  //                                q.bookingQuestionChoices.forEach((answ, indx) => {
  //                                    if (q.quantityRequired) {
  //                                        if (answ.Quantity) {
  //                                            answeredCount++;
  //                                        }
  //                                    } else if (answ.isSelectedAnswer) {
  //                                        answeredCount++;
  //                                    }
  //                                });
  //                                if (answeredCount > 0) {
  //                                    count++;
  //                                }
  //                            } else if (q.answerChoices && !q.isBookingQuestion) {
  //                                q.answerChoices.forEach((answ, indx) => {
  //                                    if (q.quantityRequired) {
  //                                        if (answ.Quantity) {
  //                                            answeredCount++;
  //                                        }
  //                                    } else if (answ.isSelectedAnswer) {
  //                                        answeredCount++;
  //                                    }
  //                                });
  //                                if (answeredCount > 0) {
  //                                    count++;
  //                                }
  //                                if (q.mandatory && answeredCount == 0) {
  //                                    this.isFormValid = false;
  //                                }
  //                            }
  //                        } else if (q.mandatory) {
  //                            this.isFormValid = false;
  //                        }
  //                    }
  //                }
  //            }
  //        });
  //      }
  //      this.item.totalAnsweredQuestions = count;
  //      this.item.totalQuestions = quesCount;
  //    }
  //    let totalPackageAnsweredQuestions = 0;
  //    let packageQuesTotal = 0;
  //    if (this.package) {
  //        for (var question of this.package.question) {

  //            var isQuestionAnswerd = this.isAnswerd(this.package.question, question);
  //            if (!question.isHidden) {
  //                packageQuesTotal++
  //                if (isQuestionAnswerd) {
  //                    totalPackageAnsweredQuestions++;
  //                }
  //            }
  //      }
  //        this.package.totalAnsweredQuestions = totalPackageAnsweredQuestions;
  //        this.package.totalQuestions = packageQuesTotal;
  //    }
  //    this.answeredCurrentCount = count + totalPackageAnsweredQuestions;
  //  }
  //  //Booking Questions
  //  else if (this.isFullBookingView) {
  //    if (this.booking) {
  //      if (this.booking.packageQuestion && this.booking.packageQuestion.length > 0) {
  //        this.booking.totalAnsweredPackageQuestions = 0;
  //        for (var pQuestion of this.booking.packageQuestion) {

  //            var isQuestionAnswerd = this.isAnswerd(this.booking.packageQuestion, pQuestion);
  //            if (!pQuestion.isHidden) {
  //                this.booking.totalPackageQuestions++;
  //                if (isQuestionAnswerd) {
  //                    this.booking.totalAnsweredPackageQuestions++;
  //                    //count++;
  //                }
  //            }
  //        }
  //      }
  //      if (this.booking.facilitySummaries && this.booking.facilitySummaries.length > 0) {
  //        for (var facilitySummary of this.booking.facilitySummaries) {
  //          facilitySummary.totalQuestions = 0;
  //          facilitySummary.totalAnswerdQuestions = 0;
  //          if (facilitySummary.bookingItemSummaries && facilitySummary.bookingItemSummaries.length > 0) {
  //            for (var bookingitemSummary of facilitySummary.bookingItemSummaries) {
  //              if (bookingitemSummary.question && bookingitemSummary.question.length > 0) {
  //                // item question
  //                for (var itemQuestion of bookingitemSummary.question) {

  //                    var isQuestionAnswerd = this.isAnswerd(bookingitemSummary.question, itemQuestion);
  //                    if (!itemQuestion.isHidden) {
  //                        if (isQuestionAnswerd) {
  //                            facilitySummary.totalAnswerdQuestions++;
  //                            //count++;
  //                        }
  //                        facilitySummary.totalQuestions++;
  //                    }
  //                }
  //              }

  //              // upsell item question
  //              if (bookingitemSummary.upsellSummaries && bookingitemSummary.upsellSummaries.length > 0) {

  //                bookingitemSummary.isUpsellHasQuestion = false;

  //                for (var upsellSummary of bookingitemSummary.upsellSummaries) {
  //                  if (upsellSummary.question && upsellSummary.question.length > 0) {
  //                    for (var upsellQuestion of upsellSummary.question) {

  //                            //even if one upsell has question it should be shown
  //                            bookingitemSummary.isUpsellHasQuestion = true;

  //                            var isQuestionAnswerd = this.isAnswerd(upsellSummary.question, upsellQuestion);
  //                            if (!upsellQuestion.isHidden) {
  //                                if (isQuestionAnswerd) {
  //                                    facilitySummary.totalAnswerdQuestions++;
  //                                    //count++;
  //                                }
  //                                facilitySummary.totalQuestions++;
  //                            }
  //                    }
  //                  }//else{
  //                  //     bookingitemSummary.isUpsellHasQuestion = false;
  //                  // }
  //                }
  //              } else {
  //                bookingitemSummary.isUpsellHasQuestion = false;
  //              }
  //            }
  //          }

  //        }
  //      }

  //    }
  //  }
  //}

  countAnswer() {
      var quesCount = 0;
      var ansCount = 0;
      this.isFormValid = true;

      if (!this.isFullBookingView) {
          if (this.item) {
              if (!this.data.isUpsellItem) {
                  for (var q of this.item.question) {
                      q.isAnswered = this.isAnswerd(this.item.question, q);
                  }
                  quesCount = this.item.question.filter(x => !x.isHidden)?.length??0;
                  ansCount = this.item.question.filter(x => !x.isHidden && x.isAnswered)?.length??0;
              }

              if (this.facilitySummary.upsellSummaries) {
                  this.facilitySummary.upsellSummaries.forEach((upsl, i) => {
                      if (upsl.question) {
                          for (var q of upsl.question) {
                              q.isAnswered =  this.isAnswerd(upsl.question, q); // Call to isAnswerd method
                          }
                          quesCount += this.item.question.filter(x => !x.isHidden)?.length??0;
                          ansCount += this.item.question.filter(x => !x.isHidden && x.isAnswered)?.length??0;
                      }
                  });
              }
              this.item.totalAnsweredQuestions = ansCount;
              this.item.totalQuestions = quesCount;
          }
          let totalPackageAnsweredQuestions = 0;
          let packageQuesTotal = 0;
          if (this.package) {
              for (var question of this.package.question) {
                 question.isAnswered =  this.isAnswerd(this.package.question, question);
              }
              this.package.totalAnsweredQuestions = this.package.question.filter(x => !x.isHidden && x.isAnswered)?.length??0;
              this.package.totalQuestions = this.package.question.filter(x => !x.isHidden)?.length??0;
          }
          this.answeredCurrentCount = ansCount;// + this.package.totalAnsweredQuestions;//facility level count with upsell
      }
      //Booking Questions
      else if (this.isFullBookingView) {
          if (this.booking) {
              if (this.booking.packageQuestion && this.booking.packageQuestion.length > 0) {

                  for (var pQuestion of this.booking.packageQuestion) {
                      pQuestion.isAnswered = this.isAnswerd(this.booking.packageQuestion, pQuestion);
                  }
                  this.booking.totalPackageQuestions = this.booking.packageQuestion.filter(x => !x.isHidden).length
                  this.booking.totalAnsweredPackageQuestions = this.booking.packageQuestion.filter(x => !x.isHidden && x.isAnswered).length;
              }
              if (this.booking.facilitySummaries && this.booking.facilitySummaries.length > 0) {
                  for (var facilitySummary of this.booking.facilitySummaries) {
                      facilitySummary.totalQuestions = 0;
                      facilitySummary.totalAnswerdQuestions = 0;
                      if (facilitySummary.bookingItemSummaries && facilitySummary.bookingItemSummaries.length > 0) {
                          for (var bookingitemSummary of facilitySummary.bookingItemSummaries) {
                              if (bookingitemSummary.question && bookingitemSummary.question.length > 0) {
                                  // item question
                                  for (var itemQuestion of bookingitemSummary.question) {

                                      itemQuestion.isAnswered = this.isAnswerd(bookingitemSummary.question, itemQuestion);
                                  }
                                  facilitySummary.totalQuestions += bookingitemSummary.question.filter(x => !x.isHidden && x.isAnswered)?.length??0;
                                  facilitySummary.totalAnswerdQuestions += bookingitemSummary.question.filter(x => !x.isHidden && x.isAnswered)?.length??0;
                              }

                              // upsell item question
                              if (bookingitemSummary.upsellSummaries && bookingitemSummary.upsellSummaries.length > 0) {

                                  bookingitemSummary.isUpsellHasQuestion = false;

                                  for (var upsellSummary of bookingitemSummary.upsellSummaries) {
                                      if (upsellSummary.question && upsellSummary.question.length > 0) {
                                          for (var upsellQuestion of upsellSummary.question) {

                                              //even if one upsell has question it should be shown
                                              bookingitemSummary.isUpsellHasQuestion = true;
                                              upsellQuestion.isAnswered = this.isAnswerd(upsellSummary.question, upsellQuestion);
                                          }
                                          this.upsellTotalQuestion += upsellSummary.question.filter(x => !x.isHidden && x.isAnswered) ?.length ??0;
                                          facilitySummary.totalQuestions += upsellSummary.question.filter(x => !x.isHidden && x.isAnswered) ?.length ??0;
                                          facilitySummary.totalAnswerdQuestions += upsellSummary.question.filter(x => !x.isHidden && x.isAnswered) ?.length ??0;

                                      }
                                  }
                              } else {
                                  bookingitemSummary.isUpsellHasQuestion = false;
                              }
                          }
                      }

                  }
              }

          }
      }
  }

  isAnswerd(question: Question[], q) {
      let IsValid = true;
      var isQuestionAnswerd = false;
      let conditionalQuestionAnswerToValidate = [];

      if ((
          (q.type == 'textQuestion' || q.type == 'booleanQuestion' || q.type == 'singleChoiceQuestion') ||
          (q.type == 'textQuestionAnswer' || q.type == 'booleanQuestionAnswer' || q.type == 'singleChoiceQuestionAnswer')
      )
          && (q.answeredText !== undefined && q.answeredText != null && q.answeredText != '')) {

          isQuestionAnswerd = true;

          if (q.type == 'booleanQuestion' || q.type == 'booleanQuestionAnswer') {
              conditionalQuestionAnswerToValidate.push((q.answeredText == 'Yes' ? 'true' : 'false'))
          } else {
              conditionalQuestionAnswerToValidate.push(q.answeredText);
          }

      }
      else if (q.type == 'multipleChoiceQuestion' || q.type == 'multipleChoiceQuestionAnswer') {
          var selectedAnswerIds = [];

          //if booking view bookingQuestionChoices will be considered package and item question scenario answerChoices
          var _choices = q.isBookingQuestion ? q.bookingQuestionChoices : q.answerChoices;
          if ((_choices ??[]).length > 0) {
              _choices.forEach((answ, indx) => {
                  if (answ.isSelectedAnswer) {
                      conditionalQuestionAnswerToValidate.push(answ.id);

                      let _qtyReq = q.question ?.quantityRequired || q.quantityRequired;
                      if ((_qtyReq && answ.Quantity > 0 ) || !_qtyReq) {
                          selectedAnswerIds.push(answ.id);
                      } else {
                          IsValid = false;//qty req question does not have qty
                      }
                  }
              });
          }
          if (selectedAnswerIds.length > 0) {
              isQuestionAnswerd = true;
          }


      } else if (q.type == 'fileUploadQuestion') {
          if (q.answerUploadedFiles) {
            let minCount: number = q.question?.minCount ?? q.minCount;
            let maxCount: number = q.question?.maxCount ?? q.maxCount ;
              // Filter out files marked for deletion
              const validFiles = q.answerUploadedFiles.filter(file => file.isDelete === undefined || file.isDelete !== true);

              if (validFiles.length > 0) {
                  const count = validFiles.length;
                if ((minCount && count < minCount) || (maxCount && count > maxCount)){
                  isQuestionAnswerd = false;
                }else {
                  isQuestionAnswerd = true;
                }

                  /*if (count >= minCount && count <= maxCount) {
                      isQuestionAnswerd = true;
                  } else if (count < minCount) {
                      isQuestionAnswerd = false;
                  } else if (count > maxCount) {
                      this.snackBar.openFromComponent(AlertMessageComponent, {
                          duration: 3000,
                          verticalPosition: 'top',
                          data: ['You cannot exceed the maximum count of ' + maxCount + ' document(s).'],
                      });
                      isQuestionAnswerd = false;
                  }*/
              } else {
                  isQuestionAnswerd = false;
              }
          }
      }
      QuestionUtility.checkForConditionalQuestion(question, q, conditionalQuestionAnswerToValidate);

      //if((q.type == 'textQuestion' || q.type == 'booleanQuestion' || q.type == 'singleChoiceQuestion' || q.type == 'fileUploadQuestion' ) && q.isHidden == true){
      //  isQuestionAnswerd = true;
      //}

      if (!q.isHidden && (!IsValid || (q.mandatory && !isQuestionAnswerd))) {
          this.isFormValid = false;
      }
      //this.updateVisibleQuestionsCount(question);
      return isQuestionAnswerd;

  }

  submitBookingData() {
    console.log('submitBookingData', this.booking);
    // return;
    if (this.isFormValid) {
      if (this.isFullBookingView) {
        this.onSubmit.emit({booking: this.booking, isAnswerChanged: this.isAnswerChanged});
        this.dialog.closeAll();
      }
    } else {
      this.openSnackBarError(['Please fill all the mandatory questions.']);
    }

  }


  submitData(data) {
    this.countAnswer();
    if (this.isFormValid) {
      if (!this.isFullBookingView && this.data.isEditMode == true) {
        this.onSubmit.emit(this.item);
        this.dialog.closeAll();
        return;
      }
      if (this.package) {
        this.data.packageFilter.PackageDetail = this.package;
        this.store.dispatch(new UpdateBookingPackage(this.data.packageFilter));
        this.dialog.closeAll();
        return;
      }
      if (this.data.isUpsellItem) {
        let item = this.facilitySummary.upsellSummaries.filter(us => us.id == this.item.id)[0];
        if (item != undefined) {
          item.question = this.item.question;
        }
      } else {
        this.facilitySummary.item.question = this.item.question;
      }
      this.store.dispatch(new UpdateFacility(this.facilitySummary));
      this.dialog.closeAll();
    } else {
      this.openSnackBarError(['Please fill all the mandatory questions.']);
    }
  }

  openSnackBarError(message) {
    this.snackBar.openFromComponent(AlertMessageComponent, {
      data: message,
      duration: 4000,
      verticalPosition: 'top'
    });
  }

  generateRandomName(): string {
    return 'answer-radio-groupUpsell' + Math.random().toString(36).substring(2);
  }


  //updateVisibleQuestionsCount(question: Question[]): void {

  //  this.visibleQuestionsCount = question.filter(question => !question.isHidden).length;

  //}


  questionAnswered(question: Question[], event: any, questionId, selectedChoice) {
    this.countAnswer();
    this.isAnswerd(question, question);
  }

  getSanitizedHtml(text: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}
