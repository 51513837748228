<main role="main" *ngIf="!enableNewDesignForMyBookingPage">
  <div class="past-booking">
    <opt-sub-header></opt-sub-header>


    <!--<button mat-button class="mmc-btn-primary" (click)="openWpMessage()">Message</button>
        <button mat-button class="mmc-btn-primary" (click)="openWpChangeRequest()">Change Request</button>
        <button mat-button class="mmc-btn-primary" (click)="openWpRequestAccess()">Request access</button>
        <button mat-button class="mmc-btn-primary" (click)="openWpAlreadyAccess()">Already access</button>-->

    <div class="container past-booking-container">
      <!-- <div class="pw-txt-h2 pw-text-semi-bold title pw-text-color__quinary txt-color-same">Booking <span class="pw-text-color__primary">Search</span></div> -->
      <div class="pw-txt-h2 pw-text-semi-bold title pw-text-color__quinary txt-color-same">
        {{(isInternalUser?"MYBOOKING.Booking": "MYBOOKING.My" )| optimotranslate}} <span
          class="pw-text-color__primary">{{(isInternalUser? "MYBOOKING.Search": "MYBOOKING.Bookings") |
          optimotranslate}}</span></div>

      <mat-tab-group (selectedTabChange)="tabChanged($event)" [selectedIndex]="tabSelectedIndex">
        <mat-tab [disabled]="!(cart$|async)?.contact?.id" (keydown.enter)="tabChanged($event)" role="tab">
          <ng-template mat-tab-label>
            <span class="tab-text pw-txt-h5">{{'MYBOOKING.Upcoming Bookings' | optimotranslate| uppercase}}</span>
          </ng-template>
        </mat-tab>
        <mat-tab [disabled]="!(cart$|async)?.contact?.id" (keydown.enter)="tabChanged($event)" role="tab">
          <ng-template mat-tab-label>
            <span class="tab-text pw-txt-h5">{{'MYBOOKING.Past Bookings' | optimotranslate| uppercase}}</span>
          </ng-template>
        </mat-tab>
      </mat-tab-group>




        <!--<form>-->
        <div class="filter-sec mmc_flex--container  mcc-wrap-items">
            <div class="mmc_flex__item--inherit mmc_flex__item--vcenter p33">
                <mat-form-field>
                    <mat-label class="pw-txt-b2">{{'MYBOOKING.Sort' |optimotranslate | uppercase}}</mat-label>
                    <mat-select #mySelect class="pw-txt-b2_small" name="sort" [(ngModel)]="sortBy" (ngModelChange)="bookingSearch()">
                        <mat-option *ngFor="let sortOption of sortOptions" [value]="sortOption.Id">{{('MYBOOKING.' + sortOption.Name) | optimotranslate | uppercase}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="mmc_flex__item--inherit mmc_flex__item--vcenter booking-sort p10">
                <button mat-icon-button (click)="toggleSort()" (keydown.enter)="toggleSort()" [disableRipple]="true" role="button" type="button" aria-label="filter button">
                    <mat-icon class="icon--small pw-ico-color__tertiary" role="button">
                        <i [ngClass]="(ascending)?'mmc icon-sort':'mmc icon-sort-1'"  role="button"></i>
                    </mat-icon>
                </button>
            </div>

        <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end booking-filter p33">
          <button mat-icon-button aria-label="Example icon-button with a heart icon"
            [disabled]="!(cart$|async)?.contact?.id" [matMenuTriggerFor]="pastBookingFilter">
            <span class="icon-p2-funnel icon--small pw-ico-color__tertiary"></span>
          </button>

          <mat-menu #pastBookingFilter="matMenu" xPosition="before" class="past-booking-filter"
            [overlapTrigger]="false">
            <div class="mmc_flex--container fd-col" (click)="$event.stopPropagation();"
              (keydown.tab)="$event.stopPropagation();">
              <div class="mmc_flex__item pr-5">
                <div class="mmc_flex--container pw-txt-b2 pb-2">{{'MYBOOKING.Status'|optimotranslate | uppercase }}
                </div>
                <div class="mmc_flex--container list-view">
                  <mat-form-field>
                    <select matNativeControl disableOptionCentering
                      placeholder="{{'MYBOOKING.Please select status' | optimotranslate}}"
                      [(ngModel)]="selectedBookingStates[0]" (valueChange)="updateStates($event)">
                      <option value="">{{'Any' | optimotranslate}}</option>
                      <option *ngFor="let n of bookingStates" [value]="n.Id">{{n.Name | optimotranslate}}</option>
                    </select>
                  </mat-form-field>
                </div>
              </div>

              <div class="mmc_flex__item">
                <div class="mmc_flex--container date-clear-wrapper">
                  <div class="mmc_flex__item--inherit mmc_flex__item--vcenter clear-all" (click)="clear()"
                    (keydown.enter)="clear()">
                    <button mat-icon-button color="" [disableRipple]="true">
                      <mat-icon><i class="material-icons pw-ico-color__tertiary d-flex">clear_all</i></mat-icon>
                      <span class="pw-txt-s2 pr-1">{{'MYBOOKING.Clear All' | optimotranslate | uppercase }}</span>
                    </button>
                  </div>
                </div>
                <div class="mmc_flex--container list-view">
                  <div class="mmc_flex__item">
                    <div class="pw-txt-s1">{{'MYBOOKING.Booking Date' | optimotranslate | uppercase }}</div>
                  </div>

                  <div class="mmc_flex--container fd-col">
                    <div class="mmc_flex__item pr-3">
                      <mat-form-field appearance="legacy">
                        <input class="pw-txt-s1" matInput [matDatepicker]="picker1"
                          placeholder="{{'MYBOOKING.From'| optimotranslate  | uppercase }}" name="bookingDateFrom"
                          [ngModel]="filter.bookingDateFrom" (ngModelChange)="filter.bookingDateFrom=$event"
                          type="text">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="mmc_flex__item">
                      <mat-form-field appearance="legacy">
                        <input class="pw-txt-s1" matInput [matDatepicker]="picker2" [min]="filter.bookingDateFrom"
                          placeholder="{{'MYBOOKING.To' | optimotranslate | uppercase }}" name="bookingDateTo"
                          [(ngModel)]="filter.bookingDateTo">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="mmc_flex__item">
                    <div class="pw-txt-s1">{{'MYBOOKING.Event Date' | optimotranslate | uppercase}}</div>
                  </div>

                  <div class="mmc_flex--container fd-col">
                    <div class="mmc_flex__item pr-3">
                      <mat-form-field appearance="legacy">
                        <input class="pw-txt-s1" matInput [matDatepicker]="picker3"
                          placeholder="{{'MYBOOKING.From'| optimotranslate | uppercase }}" name="eventDateFrom"
                          [(ngModel)]="filter.eventDateFrom">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                      </mat-form-field>
                    </div>

                    <div class="mmc_flex__item">
                      <mat-form-field appearance="legacy">
                        <input class="pw-txt-s1" matInput [matDatepicker]="picker4" [min]="filter.eventDateFrom"
                          placeholder="{{'MYBOOKING.To' | optimotranslate | uppercase }}" name="eventDateTo"
                          [(ngModel)]="filter.eventDateTo">
                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                        <mat-datepicker #picker4></mat-datepicker>
                      </mat-form-field>
                    </div>
                  </div>


                  <div class="mmc_flex__item" *ngIf="isBookingSearchByClient && contacts?.length > 1">
                    <div class="pw-txt-s1">{{'MYBOOKING.Show bookings of' | optimotranslate}}</div>
                  </div>

                  <div class="mmc_flex--container fd-col" *ngIf="isBookingSearchByClient && contacts?.length > 1">
                    <mat-form-field>
                      <mat-select class="pw-txt-b2_small" name="contacts" [(ngModel)]="filterByContact"
                        (ngModelChange)="filter.contactRef=filterByContact">
                        <mat-option [value]="'0'">All</mat-option>
                        <mat-option *ngFor="let cnt of contacts" [value]="cnt.ref">{{cnt.firstName}}
                          {{cnt.lastName}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                </div>
              </div>
            </div>
            <div class="mmc_flex--container btn-content">
              <div class="btn__card main mmc_flex__item--vcenter"
                [ngClass]="{'disable-div-btn': checkForButtonEnable(filter)}"
                [style.pointer-events]="((moment(filter.bookingDateFrom).format('L') > moment(filter.bookingDateTo).format('L')) || (moment(filter.eventDateFrom).format('L') > moment(filter.eventDateTo).format('L'))) ? 'none !important' :'auto '"
                (click)="advanceSearch()" (keydown.enter)="advanceSearch()">{{'MYBOOKING.Apply' | optimotranslate |
                uppercase }}</div>
            </div>
          </mat-menu>
        </div>

        <div class="mmc_flex__item--inherit p33 item-w100-mobile sec-search ">
          <mat-form-field class="" appearance="outline">
            <input class="pw-txt-b2_small" matInput
              placeholder="{{'MYBOOKING.Search By Booking Ref' | optimotranslate| uppercase}}"
              [(ngModel)]="filter.bookingRef" (keyup.enter)="simpleSearch()" role="textbox" aria-label="sec-search">
            <mat-icon class="pw-ico-color__tertiary search-icon" matSuffix (click)="simpleSearch()"
              (keydown.enter)="simpleSearch()">arrow_right_alt</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <!--</form>-->

      <div class="booking-list">
        <mat-accordion>
          <mat-expansion-panel [disabled]="true" *ngFor="let bk of bookings"
            [expanded]="bk.BookingId == selectedBookingId" >
            <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto" aria-hidden="true" tabindex="-1"
              aria-modal="true" style="cursor:default !important">
              <div class="mmc_flex--container">
                <div class="mmc_flex__item ">
                  <div class="mmc_flex--container mcc-wrap-items">
                    <div class="mmc_flex__item mmc_flex__item--vcenter item-w100-mobile p20">
                      <div class="mmc_flex--container--inherit mmc_fd--col">
                        <div class="mmc_flex__item--vcenter">
                          <span
                            class="mmc_flex__item--inherit pw-txt-h5 pw-text-color__primary">{{bk.BookingRef}}</span>
                          <!-- <span class="status mmc_flex__item--pcenter" [ngClass]="true ? bk.BookingStateCss: ''" [style.backgroundColor]=bk.BookingStatusColor>{{('MYBOOKING.' + bk.BookingStatusName) | optimotranslate | uppercase}}</span> -->
                          <span class="status mmc_flex__item--pcenter"
                            [ngClass]="true ? bk.BookingStateCss: ''">{{(bk.BookingState) | optimotranslate | uppercase
                            }}</span>
                        </div>
                        <div class="mmc_flex__item pw-txt-s2 pw-text-color__primary wider-text"> {{bk.EventName}}</div>
                        <div class="mmc_flex__item">
                          <span class="pw-txt-cap pw-text-color__secondary">
                            <!-- {{'MYBOOKING.Created' | optimotranslate | uppercase }} -->
                          </span><span class="pw-txt-cap pw-text-color__secondary">{{isPublicBookingPortal ?
                            (bk.BookingDate | PbCustomDate) : (bk.BookingDate | dateFormat)}} | 
                            </span>

                          <span *ngIf="isAgent" class="pw-txt-cap pw-text-color__secondary" matTooltip="Sales Person"> 
                          {{bk.SalesPersonName | uppercase}}
                          </span>

                            <span *ngIf="isAgent" class="pw-txt-cap pw-text-color__secondary"> 
                            | {{bk.ContactName | uppercase}}
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="mmc_flex__item mmc_flex__item--vcenter item-w100-mobile p25">
                      <div class="mmc_flex--container--inherit mmc_fd--col">
                        <div
                          class="mmc_flex__item mmc_flex__item--vcenter pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">
                          {{'MYBOOKING.Event Date' | optimotranslate| uppercase }}</div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter"
                          *ngIf="!(!bk.BookingTypeId || bk.BookingTypeId == null || bk.BookingTypeId == 0 || bk.BookingTypeId == 1)">
                          <span class="pw-txt-s2 pw-text-color__tertiary">
                            {{isPublicBookingPortal ? (bk.EventStartDate | PbCustomDate) : (bk.EventStartDate |
                            dateFormat)}} -
                            {{isPublicBookingPortal ? (bk.EventEndDate | PbCustomDate) : (bk.EventEndDate | dateFormat)
                            }}
                          </span>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter"
                          *ngIf="!bk.IsMultipleDay && (!bk.BookingTypeId || bk.BookingTypeId == null || bk.BookingTypeId == 0 || bk.BookingTypeId == 1)">
                          <span class="pw-txt-s2 pw-text-color__primary">{{isPublicBookingPortal ? (bk.EventStartDate |
                            PbCustomDate) : (bk.EventStartDate | dateFormat)}}</span>
                          <span class="seperator">|</span><span class="pw-txt-s2 pw-text-color__tertiary">
                            {{bk.EventStartDate |dateFormat:'time'}} - {{bk.EventEndDate |dateFormat:'time'}}
                          </span>
                        </div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter"
                          *ngIf="bk.IsMultipleDay && (!bk.BookingTypeId || bk.BookingTypeId == null || bk.BookingTypeId == 0 || bk.BookingTypeId == 1)">
                          <span class="pw-txt-s2 pw-text-color__primary">{{isPublicBookingPortal ? (bk.EventStartDate |
                            PbCustomDate) : (bk.EventStartDate | dateFormat)}}</span>
                          <span class="seperator">|</span><span class="pw-txt-s2 pw-text-color__tertiary">
                            {{bk.EventStartDate | dateFormat:'time'}}
                          </span>&nbsp;-&nbsp;
                          <span class="pw-txt-s2 pw-text-color__primary">{{isPublicBookingPortal ? (bk.EventEndDate |
                            PbCustomDate) : (bk.EventEndDate | dateFormat) }}</span>
                          <span class="seperator">|</span><span class="pw-txt-s2 pw-text-color__tertiary">
                            {{bk.EventEndDate | dateFormat:'time'}}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style="width:14%;"
                      class="mmc_flex__item mmc_flex__item--vcenter mmc_flex__item--hcenter item-w50-mobile">
                      <div class="mmc_flex--container--inherit mmc_fd--col">
                        <div
                          class="mmc_flex__item mmc_flex__item--vcenter pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">
                          {{'MYBOOKING.Outstanding' | optimotranslate | uppercase}}</div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter pw-txt-s2 pw-text-color__senary"
                          *ngIf="bk.HasInvoice">{{bk.DueAmount | CurrencyFormat}}</div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter pw-txt-s2 pw-text-color__senary"
                          *ngIf="!bk.HasInvoice">{{0 | CurrencyFormat}}</div>
                      </div>
                    </div>
                    <div class="mmc_flex__item mmc_flex__item--vcenter item-w50-mobile p10">
                      <div class="mmc_flex--container--inherit mmc_fd--col">
                        <div
                          class="mmc_flex__item mmc_flex__item--vcenter pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">
                          {{'MYBOOKING.Total' | optimotranslate | uppercase}}</div>
                        <div class="mmc_flex__item mmc_flex__item--vcenter pw-txt-s3">{{bk.GrossAmount |
                          CurrencyFormat}}</div>
                      </div>
                    </div>
                    <div class="mmc_flex__item mmc_flex__item--vcenter mmc_just-content-end item-btn-wrapper p10">
                      <div class="btn-wrapper">
                        <button type="button" class="mmc-btn-secondary--small  main mmc_flex__item--vcenter"
                          [ngClass]="!bk.HasInvoice? 'dissabled':''" mat-raised-button [disabled]="!bk.HasInvoice"
                          mat-button (click)="expand(bk)" (keydown.enter)="expand(bk)"
                          role="button">{{'MYBOOKING.Invoices' | optimotranslate| uppercase}}</button>
                      </div>
                    </div>
                    <div
                      class="mmc_flex__item--inherit mmc_flex__item--vcenter mmc_just-content-center item-btn-wrapper p10">
                      <div class="btn-wrapper" *ngIf="isUpcomingBooking && !bk.IsDisableBookingEdit" tabindex="0">
                        <a (click)="openBookingDetail(bk)" (keydown.enter)="openBookingDetail(bk)"><span
                            class="mmc-btn-secondary--small main mmc_flex__item--vcenter view_manage-btn" role="button"
                            style="cursor:pointer">{{'MYBOOKING.Manage' |optimotranslate | uppercase }}</span></a>
                      </div>
                      <div class="btn-wrapper" *ngIf="!isUpcomingBooking || bk.IsDisableBookingEdit" tabindex="0">
                        <a (click)="openBookingDetail(bk)" (keydown.enter)="openBookingDetail(bk)"><span
                            class="mmc-btn-secondary--small main mmc_flex__item--vcenter view_manage-btn" role="button"
                            style="cursor:pointer">{{'MYBOOKING.View' | optimotranslate | uppercase }}</span></a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mmc_flex__item--inherit mmc_flex__item--vcenter mmc_just-content-end icon__expand">
                  <button class="down-arrow" mat-icon-button color="primary" (click)="expand(bk)" [disableRipple]="true"
                    role="button" aria-label="expand arrow">
                    <mat-icon>{{bk.BookingId == selectedBookingId ? 'keyboard_arrow_up' :
                      'keyboard_arrow_down'}}</mat-icon>
                  </button>
                </div>
              </div>

              <!--</div>-->
            </mat-expansion-panel-header>
            <div class="mmc_expansion__body--row-header ml-2 h-mobile" [ngStyle]="{ 'background-color': bk.ContactId != contactId && isAgent ? '#e8e6e6' : 'none'}" *ngIf="bk.bookingInvoices?.length>0">
              <div class="mmc_flex--container">
                <div class="mmc_flex__item mmc_flex__item--vcenter p20">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper ml-3">{{'INVOICEINFO.Invoice'|
                    optimotranslate | uppercase }}</div>
                </div>
                <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">{{'INVOICEINFO.Due Date' |
                    optimotranslate | uppercase }}</div>
                </div>
                <div class="mmc_flex__item mmc_flex__item--vcenter p10">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">{{'INVOICEINFO.Invoice Amount' |
                    optimotranslate | uppercase }}</div>
                </div>
                <div class="mmc_flex__item mmc_flex__item--vcenter p10">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">{{'INVOICEINFO.Outstanding' |
                    optimotranslate | uppercase }}</div>
                </div>
                <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">{{'INVOICEINFO.Date Paid' |
                    optimotranslate | uppercase }}</div>
                </div>
                <div class="mmc_flex__item mmc_flex__item--vcenter p15">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper">{{'INVOICEINFO.Action' |
                    optimotranslate | uppercase }}</div>
                </div>
              </div>
            </div>

            <div class="mmc_expansion__body--row  ml-2 my-bookings-expansion-body no-invoice"
              *ngIf="!bk.bookingInvoices?.length>0">
              <div class="mmc_flex--container my-bookings-expansion-body--container mcc-wrap-items">
                <h6 class="mmc_flex__item mmc_flex__item--vcenter  mmc_flex__item--hcenter not-found">
                  {{bk.InvoiceStatus==3 ?('MYBOOKING.No invoice raised for this booking' |
                  optimotranslate):('MYBOOKING.Loading...' | optimotranslate)}}</h6>
              </div>
            </div>
            <div class="mmc_expansion__body--row ml-2 my-bookings-expansion-body" *ngFor="let inv of bk.bookingInvoices">
              <div class="mmc_flex--container my-bookings-expansion-body--container mcc-wrap-items">
                <div class="mmc_flex__item mmc_flex__item--hcenter mmc_fd--col item-w50-mobile p20">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">{{'MYBOOKING.Invoice' |
                    optimotranslate | uppercase }}</div>
                  <div class="link-primary ml-3" (click)="onInvoiceView(inv)" (keydown.enter)="onInvoiceView(inv)">
                    {{inv.InvoiceRef}}</div>
                  <div class="status ml-3" [ngClass]="inv.Status=='Unpaid' ? 'unpaid': 'paid'">{{inv.Cancelled ?
                    ('INVOICEINFO.Cancelled' | optimotranslate | uppercase) : ('INVOICEINFO.' + inv.Status |
                    optimotranslate | uppercase )}}</div>

                </div>
                <div class="mmc_flex__item mmc_flex__item--hcenter mmc_fd--col item-w50-mobile p15">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">{{'MYBOOKING.Due date' |
                    optimotranslate | uppercase }}</div>
                  <div class="pw-txt-s2">{{isPublicBookingPortal ? (inv.DueDate | PbCustomDate) : (inv.DueDate |
                    dateFormat) }}</div>
                </div>
                <div class="mmc_flex__item mmc_flex__item--hcenter mmc_fd--col item-w50-mobile p10">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">{{'MYBOOKING.InvoiceAmount' | optimotranslate}}</div>
                  <div class="pw-txt-s2 inv-amount">{{inv.InvoiceAmount | CurrencyFormat}}</div>
                </div>
                <div class="mmc_flex__item mmc_flex__item--hcenter mmc_fd--col item-w50-mobile p10">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">{{'MYBOOKING.Outstanding'
                    | optimotranslate}}</div>
                  <div class="pw-txt-s2 b-outstanding">{{inv.Outstanding | CurrencyFormat}}</div>
                </div>
                <div class="mmc_flex__item mmc_flex__item--hcenter mmc_fd--col item-w50-mobile p15">
                  <div class="pw-txt-s1 pw-text-color__tertiary pw-text-trans__upper d-mobile">{{'MYBOOKING.Date Paid' |
                    optimotranslate | uppercase}}</div>
                  <div class="pw-txt-s2">{{isPublicBookingPortal ? (inv.DatePaid | PbCustomDate) : (inv.DatePaid |
                    dateFormat) }}</div>
                </div>
                <div class="mmc_flex__item mmc_flex__item--vcenter item-w50-mobile p15">
                  <div class="btn-wrapper">
                    <button type="button" mat-button class="mmc-btn-secondary--small main mmc_flex__item--vcenter pay-now-btn"
                      role="button" [ngClass]="inv.Outstanding<=0 || inv.Cancelled || bk.Cancelled? 'dissabled':''"
                      [disabled]="inv.Outstanding<=0 ||inv.Cancelled || bk.Cancelled" *ngIf="PaymentPortalUrl"
                      (click)="RedirectToPayment(inv)"
                      (keydown.enter)="RedirectToPayment(inv)">{{"MYBOOKING.Pay Now" | optimotranslate |
                      uppercase }}</button>
                  </div>
                </div>
              </div>
            </div>

          </mat-expansion-panel>
          <div class="mmc_expansion__body--row my-bookings-expansion-body" *ngIf="bookings?.length<=0">
            <div class="mmc_flex--container my-bookings-expansion-body--container mcc-wrap-items">
              <div
                class="mmc_flex__item mmc_flex__item--vcenter  mmc_flex__item--hcenter pw-txt-h2 pw-text-color__tertiary not-found">
                <span class="icon-p2-not-found pr-2"></span>{{'MYBOOKING.No Bookings found' | optimotranslate |
                uppercase }}.</div>
            </div>
          </div>
        </mat-accordion>
        <div class="b-search-pagi search-pagi" [hidden]="bookings?.length==0">
          <mat-paginator #pastBookingPaginator [length]="recordCount" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" [pageIndex]="pagenumber" (page)="pageclickevent($event)">
          </mat-paginator>
        </div>
      </div>
    </div>

    <!--<div [hidden]="bookings?.length==0">
            <mat-paginator #pastBookingPaginator
                           [length]="recordCount"
                           [pageSize]="pageSize"
                           [pageSizeOptions]="pageSizeOptions"
                           [pageIndex]="pagenumber"
                           (page)="pageclickevent($event)">
            </mat-paginator>
        </div>-->



    <!--<div *ngIf="(showLoader)">
            <opt-mmc-loader></opt-mmc-loader>
        </div>
        <ng-template #spinner>
            <div class="container" style="min-height:400px">
                <opt-mmc-loader></opt-mmc-loader>
            </div>
        </ng-template>-->
  </div>
</main>

<!-- IMG SECTION -start ###############################################################################################  -->


<main role="main" *ngIf="enableNewDesignForMyBookingPage">
  <div class="past-booking">
    <opt-sub-header></opt-sub-header>

    <div class="container mt-5 past-booking-container">
      <!-- <div class="pw-txt-h2 pw-text-semi-bold title pw-text-color__quinary txt-color-same">Booking <span class="pw-text-color__primary">Search</span></div> -->
      <div class="pw-txt-h2 pw-text-semi-bold title pw-text-color__quinary txt-color-same" style="padding: 0px 20px;">{{'MYBOOKING.Your Orders' | optimotranslate}}
        <!-- <span class="pw-text-color__primary">{{(isInternalUser? "MYBOOKING.Search": "MYBOOKING.Bookings") |
          optimotranslate}}
        </span> -->
      </div>

      <div class="row p-2">

        <mat-tab-group class="col-lg-6 col-md-7 col-12 col-sm-8 col-xs-7 mx-sm-auto mx-md-0" (selectedTabChange)="tabChanged($event)"
          [selectedIndex]="tabSelectedIndex">
          <mat-tab class="upcomming" [disabled]="!(cart$|async)?.contact?.id" (keydown.enter)="tabChanged($event)" role="tab">
            <ng-template mat-tab-label>
              <span class="pw-txt-h2 pw-text-semi-bold">{{'MYBOOKING.Upcoming' | optimotranslate}}</span>
            </ng-template>
          </mat-tab>
          <mat-tab class="past" [disabled]="!(cart$|async)?.contact?.id" (keydown.enter)="tabChanged($event)" role="tab">
            <ng-template mat-tab-label>
              <span class="tab-text pw-txt-h2 pw-text-semi-bold">{{'MYBOOKING.Past' | optimotranslate}}</span>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
        <!--<form>-->
        <div class="col-lg-6 col-md-5 col-12 mmc_flex--container p-2">
          <div class="filter-sec ml-auto mmc_just-content-end float-right">

            <div class="mmc_flex__item--inherit p33 item-w100-mobile sec-search ">
              <mat-form-field class="" appearance="outline" [style.width]="this.selectedLanguage.toUpperCase() === 'EN' ? '215px' : '335px'">
                <input class="pw-txt-b2_small" matInput style="color:black !important"
                  placeholder="{{'MYBOOKING.Search By Booking Ref' | optimotranslate| uppercase}}"
                  [(ngModel)]="filter.bookingRef" (keyup.enter)="simpleSearch()" role="textbox" aria-label="sec-search">
                <mat-icon class="pw-ico-color__tertiary search-icon" matSuffix (click)="simpleSearch()"
                  (keydown.enter)="simpleSearch()">arrow_right_alt</mat-icon>
              </mat-form-field>
            </div>

            <div class="mmc_flex__item ml-3 mmc_flex__item--vcenter booking-filter p33">

              <mat-menu #pastBookingFilter="matMenu" xPosition="before" class="past-booking-filter"
                [overlapTrigger]="false">
                <div class="mmc_flex--container fd-col" (click)="$event.stopPropagation();"
                  (keydown.tab)="$event.stopPropagation();">
                  <div class="mmc_flex__item pr-5">
                    <div class="mmc_flex--container pw-txt-b2 pb-2">{{'MYBOOKING.Status'|optimotranslate | uppercase }}
                    </div>
                    <div class="mmc_flex--container list-view">
                      <mat-form-field>
                        <mat-label>{{'MYBOOKING.Please select status' | optimotranslate}}</mat-label>
                        <mat-select #statusDropDown [(ngModel)]="selectedBookingStates[0]" (valueChange)="updateStates($event)" disableOptionCentering>
                          <mat-option value="">{{'Any' | optimotranslate}}</mat-option>
                          <mat-option *ngFor="let n of bookingStates" [value]="n.Id">{{n.Name | optimotranslate}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="mmc_flex__item">
                    <div class="mmc_flex--container date-clear-wrapper">
                      <div class="mmc_flex__item--inherit mmc_flex__item--vcenter clear-all" (click)="clear()"
                        (keydown.enter)="clear()">
                        <button mat-icon-button color="" [disableRipple]="true">
                          <mat-icon><i class="material-icons pw-ico-color__tertiary d-flex">clear_all</i></mat-icon>
                          <span class="pw-txt-s2 pr-1">{{'MYBOOKING.Clear All' | optimotranslate | uppercase }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="mmc_flex--container list-view">
                      <div class="mmc_flex__item">
                        <div class="pw-txt-s1">{{'MYBOOKING.Booking Date' | optimotranslate | uppercase }}</div>
                      </div>

                      <div class="mmc_flex--container fd-col">
                        <div class="mmc_flex__item pr-3">
                          <mat-form-field appearance="legacy">
                            <input class="pw-txt-s1" matInput [matDatepicker]="picker1" [max]="filter.bookingDateTo"
                              placeholder="{{'MYBOOKING.From'| optimotranslate  | uppercase }}" name="bookingDateFrom"
                              [ngModel]="filter.bookingDateFrom" (ngModelChange)="filter.bookingDateFrom=$event"
                              type="text" disabled>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                          </mat-form-field>
                        </div>

                        <div class="mmc_flex__item">
                          <mat-form-field appearance="legacy">
                            <input class="pw-txt-s1" matInput [matDatepicker]="picker2" [min]="filter.bookingDateFrom"
                              placeholder="{{'MYBOOKING.To' | optimotranslate | uppercase }}" name="bookingDateTo"
                              [(ngModel)]="filter.bookingDateTo" disabled>
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="mmc_flex__item">
                        <div class="pw-txt-s1">{{'MYBOOKING.Event Date' | optimotranslate | uppercase}}</div>
                      </div>

                      <div class="mmc_flex--container fd-col">
                        <div class="mmc_flex__item pr-3">
                          <mat-form-field appearance="legacy">
                            <input class="pw-txt-s1" matInput [matDatepicker]="picker3" [max]="filter.eventDateTo"
                              placeholder="{{'MYBOOKING.From'| optimotranslate | uppercase }}" name="eventDateFrom"
                              [(ngModel)]="filter.eventDateFrom" disabled>
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3 disabled="false"></mat-datepicker>
                          </mat-form-field>
                        </div>

                        <div class="mmc_flex__item">
                          <mat-form-field appearance="legacy">
                            <input class="pw-txt-s1" matInput [matDatepicker]="picker4" [min]="filter.eventDateFrom"
                              placeholder="{{'MYBOOKING.To' | optimotranslate | uppercase }}" name="eventDateTo"
                              [(ngModel)]="filter.eventDateTo" disabled>
                            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                            <mat-datepicker #picker4 disabled="false"></mat-datepicker>
                          </mat-form-field>
                        </div>
                      </div>


                      <div class="mmc_flex__item" *ngIf="isBookingSearchByClient && contacts?.length > 1">
                        <div class="pw-txt-s1">{{'MYBOOKING.Show bookings of' | optimotranslate}}</div>
                      </div>

                      <div class="mmc_flex--container fd-col" *ngIf="isBookingSearchByClient && contacts?.length > 1">
                        <mat-form-field>
                          <mat-select class="pw-txt-b2_small" name="contacts" [(ngModel)]="filterByContact"
                            (ngModelChange)="filter.contactRef=filterByContact">
                            <mat-option [value]="'0'">All</mat-option>
                            <mat-option *ngFor="let cnt of contacts" [value]="cnt.ref">{{cnt.firstName}}
                              {{cnt.lastName}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="mmc_flex--container btn-content">
                  <div class="btn__card main mmc_flex__item--vcenter"
                    [ngClass]="{'disable-div-btn': checkForButtonEnable(filter)}"
                    [style.pointer-events]="((moment(filter.bookingDateFrom).format('L') > moment(filter.bookingDateTo).format('L')) || (moment(filter.eventDateFrom).format('L') > moment(filter.eventDateTo).format('L'))) ? 'none !important' :'auto '"
                    (click)="advanceSearch()" (keydown.enter)="advanceSearch()">{{'MYBOOKING.Apply' | optimotranslate |
                    uppercase }}</div>
                </div>
              </mat-menu>

              <button mat-icon-button aria-label="Example icon-button with a heart icon"
                [disabled]="!(cart$|async)?.contact?.id" [matMenuTriggerFor]="pastBookingFilter">
                <span class="icon-p2-funnel icon--small pw-ico-color__tertiary"></span>
              </button>
            </div>
          </div>
        </div>
        <!--</form>-->

      </div>

      <div class="booking-list">
        <mat-accordion>
          <mat-expansion-panel class="mb-3" [disabled]="true" *ngFor="let bk of bookings"
            [expanded]="bk.BookingId == selectedBookingId">
            <mat-expansion-panel-header collapsedHeight="auto" expandedHeight="auto" aria-hidden="true" tabindex="-1"
              aria-modal="true" style="cursor:default !important">
              <div class="public-invoice">
                <div class="invoice-main-table">
                  <div class="main-invoice-table-col-1">
                    <p class="invoice-col-title">{{'INVOICEINFO.Order placed' | optimotranslate}}</p>
                    <p>{{isPublicBookingPortal ? (bk.BookingDate |  PbCustomDate) : (bk.BookingDate | dateFormat)}}</p>
                    <p class="order-id">{{bk.BookingRef}}</p>
                    <p>{{(bk.BookingState) | optimotranslate | uppercase }}</p>
                  </div>
                  <div class="main-invoice-table-col-2">
                    <p class="invoice-col-title">{{'INVOICEINFO.Event' | optimotranslate}}</p>
                    <div *ngIf="!(!bk.BookingTypeId || bk.BookingTypeId == null || bk.BookingTypeId == 0 || bk.BookingTypeId == 1)">
                      <p *ngIf="!bk.IsMultipleDay">{{(bk.EventStartDate | PbCustomDate)}}</p>
                      <p *ngIf="bk.IsMultipleDay">{{ (bk.EventStartDate | PbCustomDate)}} - {{(bk.EventEndDate | PbCustomDate) }}</p>
                    </div>
                    <div *ngIf="!bk.IsMultipleDay && (!bk.BookingTypeId || bk.BookingTypeId == null || bk.BookingTypeId == 0 || bk.BookingTypeId == 1)">
                      <p>
                        {{isPublicBookingPortal ? (bk.EventStartDate | PbCustomDate) : (bk.EventStartDate | dateFormat)}} |
                        {{bk.EventStartDate |dateFormat:'time'}} - {{bk.EventEndDate |dateFormat:'time'}}
                      </p>
                    </div>
                    <div *ngIf="bk.IsMultipleDay && (!bk.BookingTypeId || bk.BookingTypeId == null || bk.BookingTypeId == 0 || bk.BookingTypeId == 1)">
                      <p>
                        {{isPublicBookingPortal ? (bk.EventStartDate | PbCustomDate) : (bk.EventStartDate | dateFormat)}} |
                        {{bk.EventStartDate | dateFormat:'time'}}
                        {{isPublicBookingPortal ? (bk.EventEndDate | PbCustomDate) : (bk.EventEndDate | dateFormat) }} |
                        {{bk.EventEndDate | dateFormat:'time'}}
                      </p>
                    </div>
                    <p *ngIf="!!bk.EventName">{{ bk.EventName }}</p>
                  </div>
                  <div class="main-invoice-table-col-3">
                    <p class="invoice-col-title">{{'MYBOOKING.Total'| optimotranslate}}</p>
                    <p>{{bk.GrossAmount | CurrencyFormat}}</p>
                  </div>
                  <div class="main-invoice-table-col-4">
                    <p class="invoice-col-title">{{'INVOICEINFO.Outstanding' | optimotranslate}}</p>
                    <p *ngIf="bk.HasInvoice">{{bk.DueAmount | CurrencyFormat}}</p>
                    <p *ngIf="!bk.HasInvoice">{{0 | CurrencyFormat}}</p>
                  </div>
                  <div class="main-invoice-table-col-5">
                    <a (click)="openBookingDetail(bk)">{{'MYBOOKING.View order details'| optimotranslate}}</a>
                  </div>
                  <div class="main-invoice-table-col-6">
                    <a (click)="expand(bk)">{{'MYBOOKING.Documents'| optimotranslate}}</a>
                    <mat-icon class="down-arrow" (click)="expand(bk)">{{bk.BookingId == selectedBookingId ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
                  </div>
                </div>
              </div>
            </mat-expansion-panel-header>

            <div class="public-invoice-details">
              <p class="invoice-title" *ngIf="bk.bookingInvoices?.length>0">{{'MYBOOKING.Invoices'| optimotranslate}}</p>
              <div class="mmc_expansion__body--row  ml-2 my-bookings-expansion-body no-invoice" *ngIf="!bk.bookingInvoices?.length>0">
                <div class="mmc_flex--container my-bookings-expansion-body--container mcc-wrap-items">
                  <h6 class="mmc_flex__item mmc_flex__item--vcenter  mmc_flex__item--hcenter not-found">
                    {{bk.InvoiceStatus==3 ?('MYBOOKING.No invoice raised for this booking' |
                    optimotranslate):('MYBOOKING.Loading...' | optimotranslate)}}</h6>
                </div>
              </div>

              <div class="invoice-table" *ngFor="let inv of bk.bookingInvoices">
                <div class="invoice-col-1">
                  <p class="invoice-col-title">{{'INVOICEINFO.Order placed' | optimotranslate}}</p>
                  <p class="invoice-id">{{inv.InvoiceRef}}</p>
                  <p>{{inv.DatePaid | PbCustomDate}}</p>
                </div>
                <div class="invoice-col-2">
                  <p class="invoice-col-title"> {{'INVOICEINFO.Due Date' | optimotranslate}}</p>
                  <p>{{inv.DueDate | PbCustomDate}}</p>
                </div>
                <div class="invoice-col-3">
                  <p class="invoice-col-title">{{'MYBOOKING.Total'| optimotranslate}}</p>
                  <p class="total-amount">{{inv.InvoiceAmount | CurrencyFormat}}</p>
                </div>
                <div class="invoice-col-4">
                  <p class="invoice-col-title">{{'INVOICEINFO.Outstanding' | optimotranslate}}</p>
                  <p  class="outstanding-amount">{{inv.Outstanding | CurrencyFormat}}</p>
                </div>
                <div class="invoice-col-5">
                    <a [ngClass]="inv.Outstanding<=0 || inv.Cancelled || bk.Cancelled? 'dissabled':''"
                      *ngIf="!(inv.Outstanding<=0 ||inv.Cancelled || bk.Cancelled) && PaymentPortalUrl" style="cursor: pointer;"
                      (click)="RedirectToPayment(inv)" (keydown.enter)="RedirectToPayment(inv)">{{'MYBOOKING.Pay Now' | optimotranslate}}</a>
                  <p *ngIf="(inv.Outstanding<=0 ||inv.Cancelled || bk.Cancelled) && PaymentPortalUrl">{{inv.Status | uppercase}}</p>
                </div>
                <div class="invoice-col-6">
                  <a (click)="onInvoiceView(inv)" (keydown.enter)="onInvoiceView(inv)">{{'MYBOOKING.View' | optimotranslate}}</a>
                </div>
              </div>

              <div class="public-invoice-document" *ngIf="bk.bookingDocuments?.length > 0">
                <p class="invoice-title">{{'MYBOOKING.Documents'| optimotranslate}}</p>

                <div class="document-table" *ngFor="let doc of bk.bookingDocuments">
                  <div class="document-col-1">
                    <p>{{doc.name}}</p>
                  </div>
                  <div class="document-col-2" (click)="onDownloadDocument(bk.BookingId, doc)">
                    <a>{{'MYBOOKING.View' | optimotranslate}}</a>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
          <div class="mmc_expansion__body--row my-bookings-expansion-body" *ngIf="bookings?.length<=0">
            <div class="mmc_flex--container my-bookings-expansion-body--container mcc-wrap-items">
              <div
                class="mmc_flex__item mmc_flex__item--vcenter  mmc_flex__item--hcenter pw-txt-h2 pw-text-color__tertiary not-found">
                <mat-icon class="mr-1 fs-3 not-found-icon">error_outline</mat-icon>{{'MYBOOKING.No Bookings found' | optimotranslate |
                uppercase }}.
              </div>
            </div>
          </div>
        </mat-accordion>

      </div>
      <div class="b-search-pagi search-pagi" [hidden]="bookings?.length==0">
        <mat-paginator style="background: none !important;" #pastBookingPaginator [length]="recordCount" [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions" [pageIndex]="pagenumber" (page)="pageclickevent($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
</main>

<!-- IMG SECTION -end ###############################################################################################  -->
