import {ConditionOperator} from 'src/modules/models/enum';
import {Question} from 'src/modules/models/item/question';
export class QuestionUtility {


  static checkForConditionalQuestion(questions: Question[], question: Question, selectedChoice: any)
  {
    //let _condQArray = questions.filter(q => q.primaryQuestionId === questionId);
      let _primQ = questions.find(q => q.id === question.id);

    if (!_primQ) {
      return;
    }

    let isAnyCondQMatched = false;

    //if (Array.isArray(_condQArray) && _condQArray.length > 0)
    {
          questions.forEach(_condQ => {

              if (_condQ.primaryQuestionId == question.id) {
                  _condQ.isHidden = false;
                  if ( !(!_condQ || !_condQ.conditionOperator)) {
                    let conditionArray = _condQ.condition.split(',');
                    conditionArray = conditionArray.map(element => element.toLowerCase());
                      let selectedValues = Array.isArray(selectedChoice) ? selectedChoice : [selectedChoice];

                      switch (_condQ.conditionOperator.toString()) {
                          case ConditionOperator.ANY_OPERATOR:
                              if (conditionArray.some(cond => selectedValues.includes(cond))

                              ) {
                                  _condQ.isHidden = false;
                                  isAnyCondQMatched = true;
                              } else {
                                  _condQ.isHidden = true;
                              }
                              break;
                          case ConditionOperator.EQUAL_OPERATOR:
                              if (
                                  conditionArray.every(cond => selectedValues.includes(cond))
                                  && conditionArray.length == selectedValues.length
                              ) {
                                  _condQ.isHidden = false;
                                  isAnyCondQMatched = true;
                              } else {
                                  _condQ.isHidden = true;
                              }
                              break;
                          case ConditionOperator.GREATERTHAN_OPERATOR:
                              if (
                                  conditionArray.some(cond => selectedValues.includes(cond))

                              ) {
                                  _condQ.isHidden = false;
                                  isAnyCondQMatched = true;
                              } else {
                                  _condQ.isHidden = true;
                              }
                              break;
                          case ConditionOperator.LESSTHAN_OPERATOR:
                              if (
                                  conditionArray.some(cond => selectedValues.includes(cond))

                              ) {
                                  _condQ.isHidden = false;
                                  isAnyCondQMatched = true;
                              } else {
                                  _condQ.isHidden = true;
                              }
                              break;
                          case ConditionOperator.ALL_OPERATOR:
                              if (
                                  conditionArray.every(cond => selectedValues.includes(cond))

                              ) {
                                  _condQ.isHidden = false;
                                  isAnyCondQMatched = true;
                              } else {
                                  _condQ.isHidden = true;
                              }
                              break;
                          default:
                              break;
                      }
                  }
              }
      });
    }

    // If none of the _condQ objects match, call setHiddenFalseForChildQuestions
    if (!isAnyCondQMatched) {
        QuestionUtility.setHiddenFalseForChildQuestions(questions, question.id);
    }
  }


  static setHiddenFalseForChildQuestions(questions: Question[], parentQuestionId: string, _freshLoad: boolean = false) {
    if (!questions || !parentQuestionId) {
      return;
    }
   // const childQuestions = questions.filter(questions => questions.primaryQuestionId === parentQuestionId);
    //console.log(childQuestions);
    if (questions.length > 0) {
        questions.forEach(child => {
            if (child.primaryQuestionId === parentQuestionId){
                child.isHidden = true;
                if (!_freshLoad) {
                    child.answeredText = undefined;
                    if(child.answerUploadedFiles){
                      child.answerUploadedFiles.forEach(file => {
                        file.isDelete = true;
                      });
                    }
                }
                // this.updateVisibleQuestionsCount(questions);
                QuestionUtility.setHiddenFalseForChildQuestions(questions, child.id, _freshLoad);
            }


      });
    }
  }


}
