<div>
  <div id="document-upload-container" class="document-upload">
    <input id="fileupload" type="file" (change)="onFileChangednew($event)" style="display:none" #fileInputTag>
    <file-drop headertext="" (onFileDrop)="dropped($event)" showBrowseBtn="true" browseBtnLabel="Select a file from your computer">
      <!--<div class="mmc_flex__item&#45;&#45;vcenter" onclick="$('#questionFileupload').trigger('click'); return false;">-->
      <div class="mmc_flex__item--vcenter" (click)="fileInputTag.click()">
        <div class="mmc_flex--container">
          <div class="mmc_flex__item--vcenter upload-icon">
            <i class="material-icons">insert_drive_file</i>
          </div>
        </div>
      </div>
      <div class="mmc_flex__item--vcenter doc-up-text--mobile pw-txt-algn-c">
        <!--<span class="link-primary" onclick="$('#questionFileupload').trigger('click'); return false;">Select a file from your device</span>-->
        <span class="link-primary" (click)="fileInputTag.click()">Select a file from your device</span>
      </div>
      <div class="mmc_flex__item--vcenter doc-up-text--desktop">
        <span class="font-family" style="padding-right:5PX; color:#808080; font-family: Tahoma;">Drop file here or </span>
        <!--<span class="font-family link-primary" onclick="$('#questionFileupload').trigger('click'); return false;">Select a file from your device</span>-->
        <span class="font-family link-primary" (click)="fileInputTag.click()">Select a file from your device</span>
        <br>
        <span class="font-family" style="padding-right:5PX; color:#808080; font-family: Tahoma;"> Required File Type : {{supportedFileType}}</span>
        <br>
        <span class="font-family" style="padding-right:5PX; color:#808080; font-family: Tahoma;" *ngIf="minCount != undefined"> Minimum files required : {{minCount}}</span>
        <br>
        <span class="font-family" style="padding-right:5PX; color:#808080; font-family: Tahoma;" *ngIf="maxCount != undefined"> Maximum files required : {{maxCount}}</span>
      </div>
    </file-drop>
    <div class="mmc_flex--container" style="display: none;">
      <div class="mmc_flex__item m-3">
        <span class="pw-txt-b3 pw-txt-p pw-text-regular pw-text-color__tertiary" [innerHtml]="CartPageDocumentDescription"></span>
      </div>
    </div>
    <div class="mmc_flex--container doc-sec" #target>
      <div class="uploaded-file-list--card" *ngIf="(answerUploadedFiles && answerUploadedFiles?.length > 0)  || selectedFilesUpladArray?.length>0">
        <div *ngIf="answerUploadedFiles?.length > 0">
          <div class="doc-container" *ngFor="let document of answerUploadedFiles" [hidden]="document.isDelete == true">
            <div class="mmc_flex--container doc-inner">
              <div class="mmc_flex__item--inherit doc-icon">
                <i class="material-icons">
                  file_copy
                </i>
              </div>
              <div class="mmc_flex__item middle-sec">
                <div class="font__normal font-family">{{ document?.name }}</div>
                <div class="font__small font__gray font-family">{{document?.fileSize}}</div>
              </div>
              <div class="mmc_flex__item--inherit down-sec">
                <a (click)="downloadDocument(id,document)"
                   *ngIf="document?.documentFileURL" >
                  <span class="material-icons font__gray">cloud_download</span>
                </a>
                <a *ngIf="!document?.documentFileURL"
                  [href]="get64StringData(document)" download="{{document?.name}}">
                  <span class="material-icons font__gray">cloud_download</span>
                </a>
              </div>
              <div class="mmc_flex__item--inherit down-sec">
                <a><i class="material-icons down-icon link-icon" (click)="removeUploadFile(document.id)">close</i></a>
                <a><div class="font__medium font__xsmall down-icon font-family" (click)="removeUploadFile(document.id)">Remove</div></a>
              </div>
            </div>
          </div>
        </div>

        <!--<div *ngIf="selectedFilesUpladArray?.length>0">
          <div class="doc-container" *ngFor="let item of selectedFilesUpladArray; let i=index">
            <div class="mmc_flex&#45;&#45;container doc-inner">
              <div class="mmc_flex__item&#45;&#45;inherit doc-icon">
                <i class="material-icons">
                  file_copy
                </i>
              </div>
              <div class="mmc_flex__item middle-sec">
                <div class="font__normal font-family">{{ item?.name }}</div>
                <div class="font__small font__gray font-family">{{item?.fileSize}}</div>
              </div>
              <div class="mmc_flex__item&#45;&#45;inherit down-sec">
                <a [href]="get64StringData(item)" download="{{item?.name}}" [target]="_blank">
                  <i class="material-icons down-icon link-icon">cloud_download</i>
                </a>
                <a [href]="get64StringData(item)" download="{{item?.name}}" [target]="_blank">
                  <div class="font__medium font__xsmall down-icon font-family">Download</div>
                </a>
              </div>
              <div class="mmc_flex__item&#45;&#45;inherit down-sec">
                <a><i class="material-icons down-icon link-icon" (click)="removeFile(item)">close</i></a>
                <a><div class="font__medium font__xsmall down-icon font-family" (click)="removeFile(item)">Remove</div></a>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</div>
