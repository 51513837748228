<main role="main">
  <form [formGroup]="individuallientSaveForm" autocomplete="off" (ngSubmit)="SaveIndividualClient()">
    <div class="mmc_corporate client_wrapper individual_customer">
      <section>
        <div class="font__semibold title">
          <div class="name">
            <div class="name__inner desktop pw-txt-h3_small pw-text-semi-bold">{{'SIGNUP.Customer Details'|
              optimotranslate}}</div>
            <div class="name__inner mobile pw-txt-h3_small pw-text-semi-bold">{{'SIGNUP.Individual Customer Details'|
              optimotranslate}}</div>
          </div>
        </div>
        <!--<div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">-->
        <!--</div>
    </div>-->


        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="row">
              <!--<div class="mmc_flex--container full-width">
              <div class="mmc_flex__item--inherit">
                  <mat-form-field appearance="outline">
                      <mat-label>Mr</mat-label>
                      <mat-select placeholder="" value="Mr">
                          <mat-option>Mr</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
              <div class="mmc_flex__item">
                  <mat-form-field appearance="outline">
                      <mat-label>First Name</mat-label>
                      <input matInput placeholder="James" calss="form-control" formControlName="FirstName" required>
                      <mat-hint></mat-hint>
                  </mat-form-field>
              </div>
          </div>-->
              <div class="col-sm-12 col-md-4 col-lg-3 remove_right_padding">
                <mat-form-field appearance="outline">
                  <mat-label>{{'MYPROFILE.Title'| optimotranslate}}</mat-label>
                  <mat-select [(value)]="SelectedTitleName">
                    <mat-option *ngFor="let title of Titles" (click)="SelectTitle(title.id)"
                      (keydown.enter)="SelectTitle(title.id)" [value]="title?.name"
                      (onSelectionChange)="SelectTitle(title.id)">{{title.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!--remove_left_padding-->
              <div class="col-sm-12 col-md-8 col-lg-9 user-name">
                <mat-form-field appearance="outline">
                  <mat-label>{{'MYPROFILE.First Name'| optimotranslate}}</mat-label>
                  <input matInput placeholder="" calss="form-control" formControlName="FirstName" required
                    autocomplete="off">
                  <mat-hint></mat-hint>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <mat-form-field appearance="outline">
              <mat-label>{{'MYPROFILE.Last Name'| optimotranslate}}</mat-label>
              <input matInput placeholder="" calss="form-control" formControlName="LastName" required
                autocomplete="off">
              <mat-hint></mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="mmc_flex--container contact-detail">
              <div class="mmc_flex__item--inherit drop-area">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>{{'MYPROFILE.Phone'| optimotranslate}}</mat-label>
                  <mat-select placeholder="{{'MYPROFILE.' + SelectedPhoneTypeName | optimotranslate}}">
                    <mat-option *ngFor="let comTypeAdPhone of CommunicationTypesPhone"
                      (click)="SelectComType(comTypeAdPhone.id, 1, false)"
                      (keydown.enter)="SelectComType(comTypeAdPhone.id, 1, false)"
                      (onSelectionChange)="SelectComType(comTypeAdPhone.id, 1, false)">{{ 'MYPROFILE.' +
                      comTypeAdPhone.name | optimotranslate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="mmc_flex__item">
                <mat-form-field appearance="outline">
                  <!-- as required validator is added from ts * mark need to be added manually -->
                  <mat-label>{{'MYPROFILE.' + SelectedPhoneTypeName | optimotranslate}} {{isPersonalPhoneNumberRequied
                    ?"*":""}}</mat-label>
                  <input matInput placeholder="{{placholderSampleNumber}}" calss="form-control"
                    formControlName="PersonalPhoneNumber" autocomplete="off">
                  <mat-error
                    *ngIf="individuallientSaveForm.controls.PersonalPhoneNumber.errors !== null && individuallientSaveForm.controls.PersonalPhoneNumber.touched">{{'MYPROFILE.Please
                    enter valid'| optimotranslate}} {{'MYPROFILE.' + SelectedPhoneTypeName | optimotranslate
                    |lowercase}} {{'MYPROFILE.number'| optimotranslate}}.</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="mmc_flex--container contact-detail">
              <div class="mmc_flex__item--inherit drop-area">
                <mat-form-field appearance="outline" floatLabel="always">
                  <mat-label>{{'MYPROFILE.Email'| optimotranslate}}</mat-label>
                  <mat-select placeholder="{{'MYPROFILE.' + SelectedEmailTypeName | optimotranslate}}">
                    <mat-option *ngFor="let comTypeAdEmail of CommunicationTypesEmail"
                      (click)="SelectComType(comTypeAdEmail.id, 2, false)"
                      (keydown.enter)="SelectComType(comTypeAdEmail.id, 2, false)"
                      (onSelectionChange)="SelectComType(comTypeAdEmail.id, 2, false)">{{ 'MYPROFILE.' +
                      comTypeAdEmail.name | optimotranslate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="mmc_flex__item">
                <mat-form-field appearance="outline">
                  <mat-label>{{'MYPROFILE.' + SelectedEmailTypeName | optimotranslate}}</mat-label>
                  <input matInput (input)="EmailAdressChanged($event.target.value)" placeholder="" calss="form-control"
                    formControlName="PersonalEmail" required autocomplete="new-password">
                  <mat-error
                    *ngIf="individuallientSaveForm.controls.PersonalEmail.errors !== null && individuallientSaveForm.controls.PersonalEmail.touched">{{'MYPROFILE.Please
                    enter valid'| optimotranslate}} {{'MYPROFILE.' + SelectedEmailTypeName | optimotranslate
                    |lowercase}}.</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <style>
          .check-icon-green {
            color: #3d9b47;
            padding-right: 10px;
          }

          .check-icon-red {
            color: #dc3545;
            padding-right: 10px;
          }
        </style>

        <!--user name-->
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <mat-form-field appearance="outline">
              <mat-label>{{'SIGNUP.Username'| optimotranslate}}</mat-label>
              <input matInput placeholder="" calss="form-control" formControlName="UserName" required
                autocomplete="off">
              <mat-hint></mat-hint>
            </mat-form-field>
          </div>
        </div>


        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <mat-form-field appearance="outline">
              <mat-label>{{'SIGNUP.Password'| optimotranslate}}</mat-label>
              <input matInput placeholder="" type="password" calss="form-control" formControlName="Password" required
                ([ngModel])="PasswordVal" autocomplete="new-password" (input)="passwordStrength($event)">
              <mat-error
                *ngIf="individuallientSaveForm.controls.Password.errors && individuallientSaveForm.controls.Password.touched">{{'SIGNUP.Invalid
                Password'| optimotranslate}}.</mat-error>



            </mat-form-field>

            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
              *ngIf="!isPasswordValid && individuallientSaveForm.controls.Password.dirty">
              <div *ngIf="pwPolicy?.EnforceComplexPassword">
                <div class="mmc_flex--container sub-section-title">



                  <div class="mmc_flex__item--inherit">
                    {{'SIGNUP.Minimum Password Requirements'| optimotranslate}}
                  </div>
                </div>
                <div class="mmc_flex--container mmc_fd--col">

                  <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label"
                    *ngIf="pwPolicy?.EnforceMinimumPasswordLength">

                    <div *ngIf="isValidCharactersMinimum">
                      <i class="material-icons check-icon check-icon-green">done</i>

                    </div>
                    <div *ngIf="!isValidCharactersMinimum">
                      <i class="material-icons check-icon check-icon-red">clear</i>

                    </div>

                    <span class="">{{pwPolicy?.PasswordLength}} {{'CHANGEPASSWORD.Characters minimum'|
                      optimotranslate}}</span>
                  </div>

                  <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label"
                    *ngIf="pwPolicy?.EnforceLowercase">
                    <div *ngIf="isValidOneLowercase">
                      <i class="material-icons check-icon check-icon-green">done</i>

                    </div>
                    <div *ngIf="!isValidOneLowercase">
                      <i class="material-icons check-icon check-icon-red">clear</i>

                    </div>



                    <span class="">{{'CHANGEPASSWORD.One lowercase'| optimotranslate}}</span>
                  </div>

                  <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label"
                    *ngIf="pwPolicy?.EnforceUppercase">

                    <div *ngIf="isValidOneUppercase">
                      <i class="material-icons check-icon check-icon-green">done</i>

                    </div>
                    <div *ngIf="!isValidOneUppercase">
                      <i class="material-icons check-icon check-icon-red">clear</i>

                    </div>



                    <span class="">{{'CHANGEPASSWORD.One uppercase'| optimotranslate}}</span>
                  </div>

                  <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label"
                    *ngIf="pwPolicy?.EnforceDigit">


                    <div *ngIf="isValidOneDigit">
                      <i class="material-icons check-icon check-icon-green">done</i>

                    </div>
                    <div *ngIf="!isValidOneDigit">
                      <i class="material-icons check-icon check-icon-red">clear</i>

                    </div>


                    <span class="">{{'CHANGEPASSWORD.One digit'| optimotranslate}}</span>
                  </div>

                  <div class="mmc_flex__item--inherit mmc_flex__item--vcenter suggestion-label"
                    *ngIf="pwPolicy?.EnforceSpecialCharacters">

                    <div *ngIf="isValidOneSpecialCharacter">
                      <i class="material-icons check-icon check-icon-green">done</i>

                    </div>
                    <div *ngIf="!isValidOneSpecialCharacter">
                      <i class="material-icons check-icon check-icon-red">clear</i>

                    </div>


                    <span class="">{{'CHANGEPASSWORD.One special character'| optimotranslate}}</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <mat-form-field appearance="outline">
              <mat-label>{{'CHANGEPASSWORD.Confirm Password'| optimotranslate}}</mat-label>
              <input matInput placeholder="" type="password" calss="form-control" formControlName="ConfirmPassword"
                required autocomplete="off">
              <mat-error
                *ngIf="individuallientSaveForm.controls.ConfirmPassword.errors !== null && individuallientSaveForm.controls.ConfirmPassword.touched">{{'CHANGEPASSWORD.New
                password and confirm password should match'| optimotranslate}}.</mat-error>
            </mat-form-field>
          </div>




        </div>
      </section>
      <section [ngClass]="loadingStyle ? 'effect-show' : ''">
        <div class="font__bold title">
          <div class="name">
            <div class="name__inner pw-txt-h3_small pw-text-semi-bold">{{'MYPROFILE.Address'| optimotranslate}}</div>
          </div>
        </div>




        <div class="individual-additinal1" *ngIf="showAddressSearchBar">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <mat-form-field appearance="outline" class="address-lookup">
                <mat-label>{{'MYPROFILE.Address'|
                  optimotranslate}}{{appData.ClientRegistrationConfig.EnableManualAddress ? "" : "*"}}</mat-label>
                <span matPrefix><i class="material-icons">person_pin_circle &nbsp;</i></span>
                <input autocomplete="off" matInput placeholder="Start typing your address" aria-label="Number"
                  [ngModel]="inputSearchString" (ngModelChange)="findAddress($event)" formControlName="Address"
                  [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let address of AddressList.AddressDetails" [value]="address.Address"
                    (click)="GetFormattedAddress(address.Address)"
                    (keydown.enter)="GetFormattedAddress(address.Address)"
                    (onSelectionChange)="GetFormattedAddress(address.Address)">
                    {{address.Address}}
                  </mat-option>
                  <mat-option *ngIf="isNoResultFound">{{'MYPROFILE.No results found'| optimotranslate}}</mat-option>
                </mat-autocomplete>
              </mat-form-field>


              <div class="address-loader ie9up" *ngIf="QASLoader && !FormattedAddressLoader">
                <mat-spinner [strokeWidth]="5" [diameter]="50"></mat-spinner>
              </div>
              <!--<div class="qas-loader" *ngIf="!QASLoader && FormattedAddressLoader">
              <mat-spinner [strokeWidth]="5" [diameter]="50"></mat-spinner>
          </div>-->
            </div>
          </div>

          <div class="row" style="display:none;">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="mmc_flex--container img-upload">
                <div class="mmc_flex__item--vcenter">
                  <span>{{'SIGNUP.Login to use saved address'| optimotranslate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="individual-additinal2">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'MYPROFILE.Address Line'| optimotranslate}}
                  1{{appData.ClientRegistrationConfig.EnableManualAddress &&
                  (appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine1?.IsMandatory ==
                  "1")?"*":""}}</mat-label>
                <input matInput maxlength="450"
                  [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine1?.Value
                  calss="form-control" formControlName="Address_Line1" [(ngModel)]="Address_Line1">
                <mat-hint></mat-hint>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'MYPROFILE.Address Line'| optimotranslate}}
                  2{{appData.ClientRegistrationConfig.EnableManualAddress &&
                  appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine2?.IsMandatory ==
                  "1"?"*":""}}</mat-label>
                <input matInput maxlength="450"
                  [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine2?.Value
                  calss="form-control" formControlName="Address_Line2" [(ngModel)]="Address_Line2">
                <mat-hint></mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'MYPROFILE.Address Line'| optimotranslate}}
                  3{{appData.ClientRegistrationConfig.EnableManualAddress &&
                  appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine3?.IsMandatory ==
                  "1"?"*":""}}</mat-label>
                <input matInput maxlength="450"
                  [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.AddressLine3?.Value
                  calss="form-control" formControlName="Address_Line3" [(ngModel)]="Address_Line3">
                <mat-hint></mat-hint>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'CHECKOUTINFO.City'|
                  optimotranslate}}{{appData.ClientRegistrationConfig.EnableManualAddress &&
                  appData?.ClientRegistrationConfig?.Placeholder?.Address?.City?.IsMandatory ==
                  "1"?"*":""}}</mat-label>
                <input matInput maxlength="100"
                  [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.City?.Value
                  calss="form-control" formControlName="Address_City" [(ngModel)]="Address_City">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <mat-form-field appearance="outline">
                <mat-label class="profile-before">{{'MYPROFILE.State'|
                  optimotranslate}}{{appData.ClientRegistrationConfig.EnableManualAddress &&
                  appData?.ClientRegistrationConfig?.Placeholder?.Address?.State?.IsMandatory ==
                  "1"?"*":""}}</mat-label>
                <input matInput maxlength="10"
                  [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.State?.Value
                  calss="form-control" formControlName="Address_CountyCode" [(ngModel)]="Address_CountyCode">
                <mat-hint></mat-hint>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'CHECKOUTINFO.Post Code'|
                  optimotranslate}}{{appData.ClientRegistrationConfig.EnableManualAddress &&
                  appData?.ClientRegistrationConfig?.Placeholder?.Address?.PostCode?.IsMandatory ==
                  "1"?"*":""}}</mat-label>
                <input matInput maxlength="50"
                  [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.PostCode?.Value
                  calss="form-control" formControlName="Address_PostCode" [(ngModel)]="Address_PostCode">
                <mat-hint></mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="!showAddressSearchBar">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'CHECKOUTINFO.Country'|
                  optimotranslate}}{{appData.ClientRegistrationConfig.EnableManualAddress &&
                  appData?.ClientRegistrationConfig?.Placeholder?.Address?.Country?.IsMandatory ==
                  "1"?"*":""}}</mat-label>
                <input type="text" aria-label="Number" matInput [formControl]="country" [matAutocomplete]="auto">
                <mat-icon matSuffix (click)="handleMatAutoComplete()">keyboard_arrow_down</mat-icon>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let option of filteredCountries | async" [value]="option"
                    (click)="SelectCountry(option.id)" (keydown.enter)="SelectCountry(option.id)"
                    (onSelectionChange)="SelectCountry(option.id)">
                    <div style="display: flex;">
                      <span [innerHTML]="option?.name"></span>
                    </div>
                    <span></span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
          <div class="row" *ngIf="showAddressSearchBar">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <mat-form-field appearance="outline">
                <mat-label>{{'CHECKOUTINFO.Country'|
                  optimotranslate}}{{appData.ClientRegistrationConfig.EnableManualAddress &&
                  appData?.ClientRegistrationConfig?.Placeholder?.Address?.Country?.IsMandatory ==
                  "1"?"*":""}}</mat-label>
                <input matInput maxlength="100"
                  [placeholder]=appData?.ClientRegistrationConfig?.Placeholder?.Address?.Country?.Value
                  calss="form-control" formControlName="Address_Country" [(ngModel)]="Address_Country">
              </mat-form-field>
            </div>
            <!--<div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div class="mmc_flex--container img-upload">
                <div class="mmc_flex__item--vcenter">
                    <span>Address Lookup</span>
                </div>
            </div>
        </div>-->
          </div>
        </div>

      </section>


      <!--Reqular Booking access Request-->
      <section *ngIf="requestToAccessReqularBookingHeaderText && enableWebPortalAccess">
        <div class="font__bold title">
          <div class="name">
            <div class="name__inner pw-txt-h3_small pw-text-semi-bold">{{ this.selectedLanguage.toUpperCase() === 'EN' ?
              requestToAccessReqularBookingHeaderText : ('SIGNUP.Regular Booking Access' | optimotranslate) }}</div>
          </div>
        </div>

        <div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="pw-txt-b1">
                <mat-checkbox class="pw-txt-b1" formControlName="Request_To_Begular_BookingAccess"
                  (change)="RegularBookingCheckboxCheck($event)" [(ngModel)]="requestToRegularBookingAccess">{{
                  this.selectedLanguage.toUpperCase() === 'EN' ? requestToAccessReqularBookingAcceptText : ('SIGNUP.I
                  would like to have access to regular booking portal' | optimotranslate) }}</mat-checkbox>
                <!--<input type="checkbox" formControlName="Request_To_Begular_BookingAccess" [(ngModel)]="requestToRegularBookingAccess" /> <span class="pl-2">{{requestToAccessReqularBookingAcceptText}}</span>-->
              </div>
            </div>
          </div>

        </div>


        <div class="individual-additinal1" *ngIf="requestToRegularBookingAccess">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="mmc_flex--container  mmc_fd--col">
                <div class="mmc_flex__item--inherit font__semibold pw-txt-h4 pw-txt-bold pt-3">
                  {{reqularBookingAccessRequestHeaderText}}
                </div>
                <div class="mmc_flex__item font__semibold pw-txt-b1 pt-3">
                  {{reqularBookingAccessRequestSubHeaderText}}
                </div>

                <div class="mmc_flex__item font__semibold mt-2 pt-3">
                  <mat-form-field class="request-access-txtarea"
                    [ngClass]="{'input-error': individuallientSaveForm.get('Request_To_Regular_Booking_Access_Note').errors &&
                individuallientSaveForm.get('Request_To_Regular_Booking_Access_Note').hasError('required') && requestToRegularBookingAccess}">
                    <textarea matInput placeholder="" ([ngModel])="requestToRegularBookingAccessNote"
                      formControlName="Request_To_Regular_Booking_Access_Note"
                      aria-label="request-access-txtarea"></textarea>
                  </mat-form-field>
                </div>


              </div>

              <div class="mmc_flex__item font__semibold pw-txt-b1 pt-3">
                {{RequestToAccessReqularBookingTypes?.QuestionHeaderText}} {{RequestToAccessReqularBookingTypes?.Mandatory
                ? '*' : ''}}
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <mat-form-field appearance="outline"
                    *ngIf="RequestToAccessReqularBookingTypes != null && RequestToAccessReqularBookingTypes?.QuestionHeaderText != ''">
                    <mat-label></mat-label>
                    <mat-select [multiple]="RequestToAccessReqularBookingTypes.MultiSelect"
                      formControlName="bookingTypesRequests">
                      <mat-option (onSelectionChange)="bookingTypesRequestCheck($event)" style="color: black"
                        *ngFor="let fv of RequestToAccessReqularBookingTypes.QuestionOptions" [value]="fv?.value">
                        {{fv?.value}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

            </div>
          </div>


        </div>
      </section>
      <section>
        <!--imageupload-->
        <div class="dp-upload-wrapper upload-profile" *ngIf="enableProfileImageUpload">
          <!--Uploaded image-->
          <div class="row  profile-image-row">
            <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <div class="mmc_flex--container user-profile">

                <div class="mmc_flex__item--inherit mmc_flex__item--vcenter">
                  <div class="profile-image" *ngIf="!profileImage">
                    <img alt="profile_image" class="img-circle" height="80" src="../dist/assets/images/user-icon.png"
                      width="80">
                  </div>
                  <div class="profile-image" *ngIf="profileImage">
                    <img alt="profile_image" class="img-circle" height="80" src={{profileImage}} width="80">
                  </div>
                </div>
                <div class="mmc_flex__item mmc_fd--col mmc_flex__item--hcenter ml-5">
                  <div class="mmc_flex--container mmc_fd--col">

                    <div class="mmc_flex__item" *ngIf="profileImage">
                      <div class="d-flex"><span class="pw-txt-s2 link-txt" (click)="profileImage=''"
                          (keydown.enter)="profileImage=''">{{'MYPROFILE.Remove Image'| optimotranslate}}</span></div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-sm-12 col-md-10 col-lg-10 col-xl-10">
              <!--Profile image uploading section-->
              <div class="document-upload" [ngClass]="{'disable-div' : profileImage}">
                <h1 class="pw-txt-h3 upload-doc-heading mmc_txt-align--center wp-mb-20">{{
                  this.selectedLanguage.toUpperCase() === 'EN' ? profileImageHeaderTextForIndividualClients :
                  ('SIGNUP.Upload your photo (maximum file size 3MB, resolution 2*2)' | optimotranslate) }}</h1>
                <div class="file-upload--card">
                  <input id="profileImageupload" type="file" (change)="onFileChangedForProfileImage($event)" #fileInput
                    style="display:none" #fileInputTagforProfileImage>
                  <file-drop class="file-drop" headertext="" (onFileDrop)="droppedForProfileImage($event)"
                    showBrowseBtn="true" browseBtnLabel="Select a file from your computer">
                    <div class="mmc_flex__item--vcenter"
                      onclick="$('#profileImageupload').trigger('click'); return false;">
                      <div class="mmc_flex--container">
                        <div class="mmc_flex__item--vcenter upload-icon mcc-file-upload">
                          <i class="material-icons pw-ico-color">insert_drive_file</i>
                        </div>
                      </div>
                    </div>
                    <div class="mmc_flex__item--vcenter doc-up-text--mobile d-mobile" *ngIf="!profileImage">
                      <span class="link-primary"
                        onclick="$('#profileImageupload').trigger('click'); return false;">{{'MYPROFILE.Select a file
                        from your device'| optimotranslate}}</span>
                    </div>
                    <div class="mmc_flex__item--vcenter doc-up-text--desktop h-mobile">
                      <span class="pw-txt-b1 pr-2 pw-text-color__tertiary font-family">{{'MYPROFILE.Drag your files here
                        or'| optimotranslate}}</span>
                      <span class="link-primary font-family"
                        onclick="$('#profileImageupload').trigger('click'); return false;">{{'MYPROFILE.Select a file
                        from your device'| optimotranslate}}</span>
                    </div>
                  </file-drop>
                </div>
              </div>
            </div>
          </div>


        </div>
      </section>


      <!--Reqular Booking access Request-->
      <!--Additional Details-->
      <div *ngIf="IsEnableToCreateAdditionalDetailsSectionForIndividualClients">
        <div class="mmc_flex--container sub-section-title">
          <div class="mmc_flex__item--inherit font__semibold pw-txt-h3_small pw-text-semi-bold">
            {{'MYPROFILE.Additional Detail'| optimotranslate}}
          </div>
          <div class="mmc_flex__item">
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="past-booking-details booking-document-upload gray-bg" id="targetFileBtn">
              <div *ngIf="priceConcessionQuestionText">
                <div class="pw-txt-b1 upload-doc-heading">{{priceConcessionQuestionText}}</div>

                <!--<div class="d-block my-3 ml-5">-->

                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">

                    <mat-form-field appearance="outline">
                      <mat-label></mat-label>
                      <mat-select placeholder="" ([ngModel])="additionalDetailNote"
                        formControlName="Additional_Detail_Note" aria-label="Additional Detail Note">
                        <mat-option *ngFor="let PriceConcessionAnswerOption of PriceConcessionAnswerOptions"
                          [value]="PriceConcessionAnswerOption.Key"
                          (click)="additionalDetailNote=PriceConcessionAnswerOption.Key"
                          (keydown.enter)="additionalDetailNote=PriceConcessionAnswerOption.Key"
                          (onSelectionChange)="additionalDetailNote=PriceConcessionAnswerOption.Key"
                          matTooltip="{{PriceConcessionAnswerOption.Value}}"
                          [matTooltipPosition]="'above'">{{PriceConcessionAnswerOption.Value}}</mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>
                </div>

              </div>
              <div class="" *ngIf="IsEnableToCreateAdditionalDocumentSectionForIndividualClients">
                <h1 class="pw-txt-b1 upload-doc-heading" [innerHtml]="ClientFileUploadDescription"></h1>
                <div class="file-upload--card">
                  <input id="fileupload" type="file" (change)="onFileChanged($event)" #fileInput style="display:none"
                    #fileInputTag>
                  <file-drop class="file-drop" headertext="" (onFileDrop)="dropped($event)" showBrowseBtn="true"
                    browseBtnLabel="Select a file from your computer">
                    <div class="mmc_flex__item--vcenter" onclick="$('#fileupload').trigger('click'); return false;">
                      <div class="mmc_flex--container">
                        <div class="mmc_flex__item--vcenter upload-icon mcc-file-upload">
                          <i class="material-icons pw-ico-color">insert_drive_file</i>
                        </div>
                      </div>
                    </div>
                    <div class="mmc_flex__item--vcenter doc-up-text--mobile d-mobile">
                      <span class="link-primary"
                        onclick="$('#fileupload').trigger('click'); return false;">{{'MYPROFILE.Select a file from your
                        device'| optimotranslate}}</span>
                    </div>
                    <div class="mmc_flex__item--vcenter doc-up-text--desktop h-mobile">
                      <span class="pw-txt-b1 pr-2 pw-text-color__tertiary font-family">{{'MYPROFILE.Drag your files here
                        or'| optimotranslate}}</span>
                      <span class="link-primary font-family"
                        onclick="$('#fileupload').trigger('click'); return false;">{{'MYPROFILE.Select a file from your
                        device'| optimotranslate}}</span>
                    </div>
                  </file-drop>
                </div>
                <div class="uploaded-file-list--card" *ngIf="additionalDetail.documents.length>0">

                  <div>
                    <div class="file-list-row" *ngFor="let item of additionalDetail.documents; let i=index">
                      <div class="mmc_flex--container">
                        <div class="mmc_flex__item--inherit icon-col">
                          <span class="material-icons">file_copy</span>
                        </div>
                        <div class="mmc_flex__item label-col">
                          <div class="mmc_flex--container mmc_fd--col">
                            <span class="font__normal font-family">{{ item?.name }}</span>
                            <span class="font__small font__gray font-family">{{item?.fileSize}}</span>
                          </div>
                        </div>
                        <div class="mmc_flex__item--inherit download-col">
                          <a [href]="get64Stringdata(item)" download="{{item?.name}}">
                            <span class="material-icons font__gray">cloud_download</span>
                          </a>
                        </div>
                        <div class="mmc_flex__item--inherit download-col">
                          <a><i class="material-icons font__gray" (click)="removeFile(i)"
                              (keydown.enter)="removeFile(i)">{{'QUESTIONPOPUP.close'| optimotranslate}}</i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--<button class="btn-cancel-booking " mat-button><span class="icon-p2-delete pr5"></span><span class="section-subheading bold">Request cancellation</span></button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <re-captcha formControlName="recaptcha"></re-captcha>-->
      <!-- https://www.npmjs.com/package/angular-google-recaptcha -->
      <!--<recaptcha [formControl]="myRecaptcha" (scriptLoad)="onScriptLoad()" (scriptError)="onScriptError()"></recaptcha>-->

      <div class="privacy-policy pw-txt-b1" *ngIf="!isPublicPortal">
        <mat-checkbox class="pw-txt-b1" [checked]="isChecked" (change)="onChange($event)">
          <div [innerHTML]="PrivacyPolicyLink"></div>
        </mat-checkbox>
      </div>
      <div class="privacy-policy pw-txt-b1" *ngIf="isPublicPortal">
        <mat-checkbox class="pw-txt-b1" [checked]="isReceivedMailChecked"
          (change)="isReceivedMailChecked=!isReceivedMailChecked"></mat-checkbox>
        <span class="mat-checkbox-txt"
          [innerHtml]="(this.selectedLanguage.toUpperCase() === 'EN') ? bookingTermAndCondition.bookingTermAndConditionMessage : ('SIGNUP.Id like to receive news and promotional information from IMG Events and its official sponsors and partners via email' | optimotranslate)"></span>
        <br>
        <mat-checkbox class="pw-txt-b1" [checked]="isChecked" (change)="onChange($event)"></mat-checkbox>
        <span class="mat-checkbox-txt"
          [innerHtml]="(this.selectedLanguage.toUpperCase() === 'EN') ? bookingTermAndCondition.termsAndConditionContents.signUpContent : ('SIGNUP.I agree to the IMG Terms of Use, Purchase Policy and Privacy Policy' | optimotranslate)"></span>
      </div>
      <div class="recaptcha mmc_flex--container"><!--*ngIf="isLoad && tabIndex==1"-->
        <ngx-recaptcha2 #cap123 [siteKey]="siteKey" [size]="size" [hl]="selectedLanguage" [theme]="theme" [type]="type"
          (expire)="handleExpire()" (load)="handleLoad()" (success)="handleSuccess($event)" formControlName="recaptcha1"
          id="456">
        </ngx-recaptcha2>
      </div>
      <div class="mmc_flex--container btn-content">
        <!--<div class="mmc_flex__item--vcenter">
         Manningham <a  class="" (click)="OpenPrivacyPolicy()"> Privacy Policy </a> & <a  (click)="OpenTermAndConditions()"> Terms and Conditions</a>
    </div>-->

        <div class="mmc_flex__item--vcenter" *ngIf="!isPublicPortal">
          <i class="material-icons">
            chevron_left
          </i>
          <a class="pw-txt-h4" href="javascript:void(0);" (click)="Redirect($event,1,CurrentUrl)"
            (keydown.enter)="Redirect($event,1,CurrentUrl)"
            *ngIf="isFromBooking=='1' || CurrentUrl == 'fromBookingDetail'">BOOKING DETAILS </a>
          <a class="pw-txt-h4" href="javascript:void(0);" (click)="Redirect($event,2)"
            (keydown.enter)="Redirect($event,2)"
            *ngIf="isFromBooking!='1' && CurrentUrl != 'fromBookingDetail' && CurrentUrl != 'fromWasteBooking'">VENUES</a>
          <a class="pw-txt-h4" href="javascript:void(0);" (click)="Redirect($event,2,CurrentUrl)"
            (keydown.enter)="Redirect($event,2,CurrentUrl)" *ngIf="CurrentUrl == 'fromWasteBooking'">HOME</a>
          <!--<button type="button" class="btn__card main mmc_flex__item--vcenter" (click)="RedirectToBookingDetail()" mat-button>BOOKING DETAILS</button>-->
        </div>
        <div class="mmc_flex__item--vcenter">
          <div>
            <button type="submit" class="btn__card btn-continue main mmc_flex__item--vcenter"
              [disabled]="isDisableClientSaveBtn || isChecked==false || isCaptchaVerified==false ||(IsEnableManualAddress==false && (QASLoader || addressLoaded== false))"
              [ngClass]="{'disable-div' : isChecked==false || isCaptchaVerified==false || (IsEnableManualAddress==false && (QASLoader || addressLoaded== false))}"
              mat-button>
              {{'SIGNIN.Continue'| optimotranslate |uppercase}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>