<main role="main">

  <div *ngIf="enableNewDesignForLoginPage" style="background: #000; margin-top: 55px;">
    <span (click)="navigateToHome()">
        <i class="material-icons" style="color: #fff !important; padding: 15px; cursor: pointer;">
            home
        </i>
      </span>
  </div>

  <div class="login" *ngIf="!enableNewDesignForLoginPage">
    <div class="container-fluid remove-padding">
      <div class="container">
        <div class="wm-stepper-content" *ngIf="wasteBooking && wasteBookingFromCart">
          <mat-horizontal-stepper #WmStepper labelPosition="bottom" selectedIndex="2">
            <mat-step editable="false">
              <ng-template matStepLabel>{{WMComponentsTitleText.STEPPER_ONE}}</ng-template>
            </mat-step>
            <mat-step editable="false">
              <ng-template matStepLabel>{{WMComponentsTitleText.STEPPER_TWO}}</ng-template>
            </mat-step>
            <mat-step editable="false">
              <ng-template matStepLabel>{{WMComponentsTitleText.STEPPER_THREE}}</ng-template>
            </mat-step>
            <mat-step editable="false" class="wm-pointer-none" #stepper>
              <ng-template matStepLabel>{{WMComponentsTitleText.STEPPER_FOUR}}</ng-template>
            </mat-step>
          </mat-horizontal-stepper>
        </div>
        <div class="login__wrap row">
          <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 login__welcome light-on-dark">
            <div class="login__center login__left-sec">
              <div class="font__upper login__welcome-text">{{'SIGNIN.Welcome'| optimotranslate}}</div>
              <div class="txt-summery"
                *ngIf="!signUpButtonSetting || (signUpButtonSetting && signUpButtonSetting.Disabled == Condition.FALSE)">
                <p class="text-center login__register" style="font-weight: bold !important;">
                  {{'Register with us to submit your Booking Enquiry!'| optimotranslate}}
                </p>
              </div>
            </div>
            <div class="login__center"
              *ngIf="!signUpButtonSetting || (signUpButtonSetting && signUpButtonSetting.Disabled == Condition.FALSE)">
              <button class="btn login__btn--signup btn--light font__upper font__large font__regular "
                (click)="signupClick()" (keydown.enter)="signupClick()" tabindex="0">
                {{'SIGNIN.Sign Up'| optimotranslate}}
              </button>
            </div>
            <div class="login__center mt-2" *ngIf="directLoginFromPublicWeb">
              <button *ngIf="enableGuestCheckout"
                class="btn login__btn--signup btn--light font__upper font__large font__regular"
                (click)="guestCheckout()" (keydown.enter)="guestCheckout()" tabindex="0">
                {{'SIGNIN.Guest Checkout'| optimotranslate}}
              </button>
            </div>
            <a *ngIf="!homeSetting || (homeSetting && !homeSetting.Disabled)" class="goto-home mmc_flex--container"
              (click)=" navigateToHome()" style="cursor: pointer;">
              <span>{{'SIGNIN.Back to home'| optimotranslate}}</span>
            </a>
            <!-- <ng-template #backToHome>
            <a style="cursor:pointer;text-decoration: underline;"class="goto-home mmc_flex--container" (click)=" navigateToHome()">
              <span>{{'USER.Back to home'|optimotranslate}}</span>
            </a>
          </ng-template> -->
            <ul class="bg-bubbles">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 login__bg">
            <div class="login__signin dark-on-light">
              <!--Start- language-selector -->
              <div class="lang-selector-wrapper">
                <!--Start- language-selector -->
                <opt-language-selector></opt-language-selector>
                <!--End- language-selector -->
              </div>
              <div class="login__right-sec">
                <div class="text-center login__title login__signin-text font__h4">{{'SIGNIN.Sign In'| optimotranslate}}
                </div>
                <p class="text-center login__sub font__sz--16 font__w--400">{{'SIGNIN.Sign in with Email Address'|
                  optimotranslate}}</p>
                <form novalidate (ngSubmit)="onSubmit()" [formGroup]="frmLogin">
                  <div class="login__name mmc_flex--container">
                    <div class="mmc_flex__item--vcenter" style="font-size:20px; margin-right:20px;">
                      <span class=" icon-user icon f-size-md"></span>
                    </div>
                    <div class="mmc_flex__item flex-grow-1">
                      <mat-form-field class="example-full-width font__normal">
                        <input value="" type="text" placeholder="{{'SIGNIN.Username'| optimotranslate}}" matInput
                          formControlName="username" [errorStateMatcher]="matcher" name="username" autocomplete="off" required>
                        <mat-error *ngIf="frmLogin.controls.username.invalid && frmLogin.controls.username.touched">
                          {{'SIGNIN.Please enter a valid username' | optimotranslate}}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="login__password mmc_flex--container">
                    <div class="mmc_flex__item--vcenter">
                      <img src="../dist/assets/images/lock-icon.svg" alt="" />
                    </div>
                    <div class="mmc_flex__item flex-grow-1">
                      <mat-form-field class="example-full-width font__normal">
                        <input value="" matInput type="password" placeholder="{{'SIGNIN.Password'| optimotranslate}}"
                          formControlName="password" autocomplete="off" required>
                      </mat-form-field>
                    </div>
                  </div>
                  <span *ngIf="errorMessage!=''" class="error-login">{{'SIGNIN.' + errorMessage |
                    optimotranslate}}</span>

                  <div class="login__center mt-4">
                    <button tabindex="0" class="btn login__submit font__large font__regular font__upper">
                      {{'SIGNIN.Continue' | optimotranslate}}
                    </button>
                  </div>
                </form>
              </div>

              <div class="login__center pw-txt-s2">
                <div *ngIf="displayForgotUserNameLink">
                  <a href="#/client/forget-password" routerLink="/client/forget-password"
                    class="text-center login__forgot cog-d-none">
                    {{'SIGNIN.Forgot your username?' | optimotranslate}}
                  </a>
                </div>

                <div class="pw-txt-s2 px-2 cog-d-none" *ngIf="displayForgotUserNameLink">{{"SIGNIN.Or" | optimotranslate |
                  uppercase}}</div>
                <div style="cursor: pointer;">
                  <a (click)="goToForgotPassword()" class="text-center login__forgot">
                    {{'SIGNIN.' + ForgotPasswordLink | optimotranslate}}
                  </a>
                </div>

              </div>
              <div class="sigup--mobile text-center">
                <div class="signup-checkout sigup--mobile"
                  *ngIf="!signUpButtonSetting || (signUpButtonSetting && signUpButtonSetting.Disabled == Condition.FALSE)">
                  {{"SIGNIN.Don't have an account?" | optimotranslate}}
                  <label style="color:#0275d8;margin-bottom: 0 !important;" (click)="signupClick()"
                    class="sigup--mobile__link font__upper font__large font__regular"
                    (keydown.enter)="signupClick()">{{'SIGNIN.Sign Up'|optimotranslate}}</label>
                  <div class="login__center mt-2" *ngIf="directLoginFromPublicWeb">
                    <label style="color:#0275d8" *ngIf="enableGuestCheckout"
                      class="sigup--mobile__link font__upper font__large font__regular" (click)="guestCheckout()">
                      {{'SIGNIN.Guest Checkout' | optimotranslate}}
                    </label>
                  </div>
                </div>
                <div *ngIf="!homeSetting || (homeSetting && !homeSetting.Disabled)"><a style="color:#0275d8"
                    class="goto-home" (click)=" navigateToHome()" style="cursor: pointer;">{{'SIGNIN.Back to home' |
                    optimotranslate}}</a></div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="((loader$|async)?.isLoading_bookingSave)">
                <opt-mmc-loader></opt-mmc-loader>
            </div> -->

      </div>
    </div>
  </div>

  <!-- New LOGIN  Design-->
  <div class="img-login" *ngIf="enableNewDesignForLoginPage">
    <div class="login">
      <div class="container-fluid remove-padding">
        <div class="container">
          <div class="login__wrap row">

            <div class="col-sm-12 col-md-12 col-lg-5 col-xl-4 login__bg">
              <div class="login__signin dark-on-light">

                <div class="login__right-sec">
                  <div class="signin-title">
                    <div class="img-icon">
                      <img src="https://assets.primesport.com/Content/Labels/45/IMG-logo-footer-v2.jpg" />
                    </div>
                    <div class="img-login-sub">
                      <div class="text-center"> {{'SIGNIN.Events' | optimotranslate}}</div>
                      <div class="signin-line"></div>
                    </div>
                  </div>

                  <form novalidate (ngSubmit)="onSubmit()" [formGroup]="frmLogin">
                    <div class="login__name mmc_flex--container">
                      <!-- <div class="mmc_flex__item--vcenter" style="font-size:20px; margin-right:20px;">
                        <span class=" icon-user icon f-size-md"></span>
                      </div> -->
                      <div class="mmc_flex__item flex-grow-1">
                        <mat-form-field class="example-full-width font__normal" style=" height: 10px !important">
                          <input style="padding-bottom: 5px !important;" value="" type="text" placeholder="{{'SIGNIN.Username'| optimotranslate}}" matInput
                            formControlName="username" [errorStateMatcher]="matcher" name="username" autocomplete="off" required>
                          <mat-error *ngIf="frmLogin.controls.username.invalid && frmLogin.controls.username.touched">
                            {{'SIGNIN.Please enter a valid username' | optimotranslate}}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="login__password mmc_flex--container">
                      <!-- <div class="mmc_flex__item--vcenter">
                        <img src="../dist/assets/images/lock-icon.svg" alt="" />
                      </div> -->
                      <div class="mmc_flex__item flex-grow-1">
                        <mat-form-field class="example-full-width font__normal" style=" height: 10px !important">
                          <input style="padding-bottom: 5px !important;" value="" matInput type="password" placeholder="{{'SIGNIN.Password'| optimotranslate}}"
                            formControlName="password" autocomplete="off" required>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="error-login">
                      <span *ngIf="errorMessage!=''">{{errorMessage}}</span>
                    </div>

                    <div class="row signin-options">
                      <div class="col-6">
                        <div style="display: flex !important; align-items: center;gap: 5px" class="d-flex">
                          <img *ngIf="selectedLanguage !=='' && languages && languages.length > 1"
                            src="../dist/assets/country-flags/{{selectedLanguage}}.png"
                            class="signin-chevron-down ml-2">
                          <div *ngIf="languages && languages.length > 1" style="width: 50px;padding-left: 5px;">
                            <mat-select [(ngModel)]="selectedLanguage" [ngModelOptions]="{standalone: true}"
                              (ngModelChange)="switchLanguage()" panelClass="dropClass">
                              <mat-option *ngFor="let language of languages" value="{{ language.languageCode }}"
                              style="width:100px;">
                                <img style="height: 15px; width: 15px;" src="../dist/assets/country-flags/{{language.languageCode}}.png">
                                {{language.languageCode}}
                              </mat-option>
                            </mat-select>
                          </div>
                          <!-- <img *ngIf="selectedLanguage !==''"
                            src="../dist/assets/country-flags/{{selectedLanguage}}.png"
                            class="signin-chevron-down ml-2"> -->
                          <!-- <div>English</div>
                          <img class="signin-chevron-down ml-2"
                            src="../dist/assets/images/img/login/chevron-down.png" /> -->

                        </div>
                      </div>
                      <div class="col-6" style="cursor: pointer;">
                        <a (click)="goToForgotPassword()" class="text-end login__forgot">
                          {{'SIGNIN.' + ForgotPasswordLink | optimotranslate}}
                        </a>
                      </div>
                    </div>


                    <div class="login__center mt-4">
                      <button tabindex="0" class="btn login__submit font__large font__regular font__upper">
                        {{'SIGNIN.Login'| optimotranslate}}
                      </button>
                    </div>
                  </form>
                </div>


                <div class="login__center pw-txt-s2">
                  <div *ngIf="displayForgotUserNameLink">
                    <a href="#/client/forget-password" routerLink="/client/forget-password"
                      class="text-center login__forgot cog-d-none">
                      {{'SIGNIN.Forgot your username?' | optimotranslate}}
                    </a>
                  </div>

                  <div class="pw-txt-s2 px-2 cog-d-none" *ngIf="displayForgotUserNameLink">
                      {{"SIGNIN.Or" | optimotranslate | uppercase}}</div>
                </div>

                <div *ngIf="isSocialMediaLogin">
                  <div class="text-center mt-3 mb-3">
                    Login With
                  </div>
                  <div class="d-flex">
                    <a href="www.google.com" class="col-3">
                      <img src="../dist/assets/images/img/login/Icon awesome-google.png" />
                    </a>
                    <a href="www.google.com" class="col-3">
                      <img src="../dist/assets/images/img/login/Icon awesome-twitter.png" />
                    </a>
                    <a href="www.google.com" class="col-3">
                      <img src="../dist/assets/images/img/login/Icon awesome-instagram.png" />
                    </a>
                    <a href="www.google.com" class="col-3">
                      <img src="../dist/assets/images/img/login/Icon awesome-facebook.png" />
                    </a>
                  </div>
                </div>

                <div>
                  <div style="cursor: default;" *ngIf="!signUpButtonSetting || (signUpButtonSetting && signUpButtonSetting.Disabled == Condition.FALSE)">
                    {{"SIGNIN.Don't have an account?" | optimotranslate}} <a style="cursor: pointer;" (click)="signupClick()"
                      (keydown.enter)="signupClick()" tabindex="0" class="link-color bold-20">{{'SIGNIN.Sign Up'|optimotranslate}}</a>
                  </div>
                </div>

                <div class="mt-3 text-center"*ngIf="enableGuestCheckout">
                  <div class="">
                    or
                  </div>
                  <div>
                    <a class="bold-20 link-color" (click)="guestCheckout()" (keydown.enter)="guestCheckout()"
                      tabindex="0">
                      Checkout as a guest</a>
                  </div>
                </div>



              </div>
            </div>
          </div>


          <div *ngIf="((loader$|async)?.isLoading_bookingSave)">
            <opt-mmc-loader></opt-mmc-loader>
          </div>
        </div>


      </div>
    </div>
  </div>
</main>
