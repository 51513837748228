<div *ngIf="showErrorMessage"  id="topElement">

    <wp-error-message [errorMessageType]="2" [MessageDetail]="messageDetail"></wp-error-message>
</div>

<div class="wp-add-teams">
    <div class="row wp-section-title">
        <div class="col-sm-12">
            <div class="wp-txt-body1 wp-txt-title rb-mobile-font-16 rb-mobile-font-bold">Request your bookings</div>
            <div class="wp-txt-body1 wp-txt-subtitle rb-mobile-font-14 ">Request your desired activity type, name and participants</div>
        </div>
    </div>
    <div class="add-teams-header rb-mobile-none">
        <div class="wp_flex--container">
            <div class="wp_flex__item wp_flex__item--vcenter p33">
                <div class="wp-txt-body2">Activity Type</div>
            </div>
            <div class="wp_flex__item wp_flex__item--vcenter p50">
                <div class="wp-txt-body2">Activity Name</div>
            </div>
            <div class="wp_flex__item wp_flex__item--vcenter justify-content-end p25">
                <div class="wp-txt-body2">Participants</div>
            </div>
        </div>
    </div>
 
        <div >
            <div class="add-teams-row rb-mobile-grid rb-mobile-justify-normal " *ngFor="let teamGroup of teamGroups; let i = index">
                <div class="left-col rb-w-100 rb-mb-20">
                    <div class="wp_flex--container rb-mobile-grid">
                        <div class="wp_flex__item wp_flex__item--vcenter p33 rb-w-100 rb-mobile-grid rb-mb-20">
                            <div class="rb-web-none rb-mobile-font-14">Activity Type</div>
                            <mat-form-field class="wp-pr-30 rb-p-0" style="width:100% !important;" >
                                <mat-select panelClass="event-select-panel" [disabled] ="teamGroup.disable" (selectionChange)="PackageChange($event , i)" #data  [ngModelOptions]="{standalone: true}" [(ngModel)]="teamGroup.packageId" matTooltip="{{allSelectedActivity[i]}}">  <!--(ngModelChange)='SetExistingPackages("24")'-->                                  
                                    <!--<mat-option  [value]="besp.id"  *ngFor="let besp of bespokePackages" >-->
                                <mat-placeholder>Select a Package</mat-placeholder>    
                                <mat-option  [value]="besp.id"  *ngFor="let besp of teamGroup.packages" matTooltip="{{besp.name}}">
                                        {{besp.name}}
                                    </mat-option>
                              </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="wp_flex__item wp_flex__item--vcenter p50 rb-w-100 rb-mobile-grid rb-mb-20">
                            <div class="rb-web-none rb-mobile-font-14">Activity Name</div>
                            <mat-form-field class="wp-pr-30 rb-p-0" [floatLabel]="'never'"  [ngClass]="{'wp-form-field--error':(teamGroup.activityGroup== '' || teamGroup.activityGroup==undefined)?true:false}">
                                <input matInput placeholder="Add your Team, Group or Activity Name" [ngModelOptions]="{standalone: true}" [(ngModel)] ="teamGroup.activityGroup" [ngClass]="{'wp-form-field--error':(teamGroup.activityGroup== '' || teamGroup.activityGroup==undefined)?true:false}"/> 
                            </mat-form-field>
                        </div>
                        <div class="wp_flex__item wp_flex__item--vcenter p25 rb-w-100 rb-mobile-grid rb-mb-20">
                            <div class="rb-web-none rb-mobile-font-14">Participants</div>
                            <mat-form-field class="wp-txt-align--right"  [ngClass]="{'wp-form-field--error':(teamGroup.participants==0 || teamGroup.participants==null)?true:false}">
                                <input type="number"   (keydown)="applyNumberFilter($event)"  [min]="0" [max]="9999" matInput placeholder="" [ngModelOptions]="{standalone: true}"  [(ngModel)] ="teamGroup.participants"  /> 
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="right-col" >

                    <div class="wp_flex__item wp_flex__item--vcenter rb-w-100" >
                        <button (click)="removeForm(teamGroup,i)" (keydown.enter)="removeForm(teamGroup,i)" class="mat-btn-icon btn-delete rb-w-100" mat-button  matTooltip="Delete Activity"><i class="material-icons">delete</i></button>
                    </div>
                </div>
            </div>
        </div>

    

    <button mat-button class="add-more-button rb-w-100" (click)="clickAddMore()" (keydown.enter)="clickAddMore()">
        <div class="wp_flex--container wp-perfect-center">
            <div class="wp_flex__item--inherit wp_flex__item--vcenter">
                <i class="material-icons">add</i>
            </div>
            <div class="wp_flex__item--inherit wp_flex__item--vcenter">
                <div class="wp-txt-body1">Add Another Request</div>
            </div>
        </div>
    </button>
    <!--<div>
        <button type="button" class="btn-info" (click)="BookingSave()">
            Save Booking
        </button>
    </div>-->
    <!--<div>
        <button type="button" class="btn-info" (click)="SlectBooking(24)">
            Slelect Booking
        </button>
    </div>-->


</div>